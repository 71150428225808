









































































import Swal from 'sweetalert2'
import Vue from 'vue'
import { contractGetApi, contractUpdateApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Table from '@/components/Table.vue'
import Button from '@/components/table/Button.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as teacherService from '@/store/teachers/service'
import Modal from '@/components/Modal.vue'
import StopContract from '@/pages/studentsByGroups/StopContract.vue'
import * as contractService from '@/store/contracts/service'
import { mapGetters } from 'vuex'
import ProgramAuthModal from '@/components/main/ProgramAuthModal.vue'

export default Vue.extend({
  props: {
    contractId: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      loading: false,
      mainData: {} as any,
      contractColumns: [
        { type: 'Label', label: 'Договор', name: '', params: { lblClass: 'biggerBold' } },
        { type: 'Input', label: '№', name: 'number', width: '25%' },
        { type: 'DatePicker', label: 'С', name: 'dateFrom', validation: 'required|date', width: '25%' },
        { type: 'DatePicker', label: 'по', name: 'dateTo', validation: 'required|date', width: '25%' },
        { type: 'Input', label: 'Сумма', name: 'contractSum', width: '25%', readonly: true },
        { type: 'Hidden', name: 'contractId' },
        { type: 'Label', label: 'Ребенок', name: '', params: { lblClass: 'biggerBold mt-2' } },
        { type: 'Input', label: 'Фамилия', name: 'userLastName', width: '25%', readonly: true },
        { type: 'Input', label: 'Имя', name: 'userFirstName', width: '25%', readonly: true },
        { type: 'Input', label: 'Отчество', name: 'userMiddleName', width: '25%', readonly: true },
        { type: 'Input', label: 'Дата рождения', name: 'userBirthday', width: '25%', readonly: true },
        { type: 'Hidden', name: 'userId' },
        { type: 'Label', label: 'Плательщик', name: '', params: { lblClass: 'biggerBold mt-2' } },
        { type: 'Input', label: 'ФИО плательщика', name: 'parent', width: '50%' },
        { type: 'Input', label: 'Телефон плательщика', name: 'phone', width: '50%' },
        { type: 'Input', label: 'Паспорт', name: 'passport' }
      ],
      contractFormResult: {} as any,
      contractServicesTableColumns: [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName' },
        { title: 'Ведет', name: 'groupTeachersNames', html: true },
        { title: 'С', name: 'dateFrom' },
        { title: 'По', name: 'dateTo' },
        { title: 'Шаблон договора', name: 'contractTemplateName' },
        { title: 'Стоимость', name: 'serviceSum' }
      ],
      contractServicesTableData: [] as any,
      routerBackQuery: null as any,
      openModal: false,
      modalTitle: null as any
    }
  },
  mounted () {
    // console.log(this.$store.getters['navigation/getPageNavigation'](this.$route.name, 'stepBack'))
    this.getContract()
  },
  methods: {
    onStepBack () {
      const stepBackRoute = this.$store.getters['navigation/getPageNavigation'](this.$route.name, 'stepBack')
      if (stepBackRoute) {
        this.$router.push({ name: stepBackRoute })
      } else {
        this.$router.go(-1)
      }
    },
    onClose () {
      this.openModal = false
    },
    stopContractModalOpen () {
      this.openModal = true
      this.modalTitle = 'Прекращение действия договора ' + this.mainData.number
    },
    updateContractFormResult (data: any) {
      this.contractFormResult = data
    },
    async getContract () {
      this.loading = true
      try {
        const result = await contractService.loadContractIfNone({ id: Number(this.contractId) })

        if (result) {
          let user = result.contractServices[0].userGroup.user.userDetail
          user = f.convertNameInObject(user)
          this.mainData = {
            number: result.number,
            dateFrom: result.dateFrom,
            dateTo: result.dateTo,
            contractSum: Number(result.sum),
            contractId: result.id,

            userLastName: user.lastName,
            userFirstName: user.firstName,
            userMiddleName: user.middleName,
            userBirthday: user.birthday,
            userId: user.id,

            parent: result.parent,
            phone: result.phone,
            passport: result.passport
          }
          this.contractColumns = f.addValuesToColumns(this.contractColumns, this.mainData)
          this.contractServicesTableData = result.contractServices.map((el: any) => {
            el.serviceName = el.service.name
            el.serviceSum = el.service.sum
            el.groupName = el.userGroup.group.name
            el.contractTemplateName = el.service.templateName
            return el
          })
          this.routerBackQuery = this.$route.query
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    async updateContract () {
      try {
        this.loading = true
        const obj = this.contractFormResult
        obj.id = Number(this.contractFormResult.contractId)
        obj.number = this.contractFormResult.number
        delete obj.userLastName
        delete obj.userFirstName
        delete obj.userMiddleName
        delete obj.userBirthday
        delete obj.userId
        const result = await this.$store.dispatch('contracts/updateContract', obj)
        if (result) {
          Swal.fire({ title: 'Договор сохранен', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        // console.log(error.message)
        Swal.fire('Ошибка', (error as any).data ? (error as any).data.errorMessage : (error as any).message ? (error as any).message : error, 'error')
      } finally {
        this.loading = false
        this.getContract()
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    ...mapGetters({
      checkPermissions: 'user/checkPermissions'
    }),
    computedContractColumns (): any {
      let tempColumns = JSON.parse(JSON.stringify(this.contractColumns))
      if (this.checkPermissions([4])) {
        tempColumns = tempColumns.map((col: any) => {
          if (col.type !== 'Label') {
            col.readonly = true
          }
          return col
        })
      }
      return tempColumns
    }
  },
  components: {
    FormBuilder,
    Table,
    LoadingSpinner,
    Button,
    Modal,
    StopContract
    // ProgramAuthModal
  }
})

