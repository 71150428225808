
































































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { parseAxiosError, ServerError } from '@/services/api'
import { findByPaymentKeyAndDateOfBirthApi, getClassroomsApi, getClassroomStudentsApi, createUserApi } from '@/store/servicesSignUp/api-requests'
import { getGroupsByServiceIdApi } from '@/store/groups/api-requests'
import { addToCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import FormBuilder from '@/components/form/FormBuilder.vue'
import CartList from '@/pages/cart/CartList.vue'
import moment from 'moment'
import Modal from '@/components/Modal.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'
import * as groupService from '@/store/groups/service'
import * as ssuService from '@/store/servicesSignUp/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'

export default Vue.extend({
  props: [],
  data () {
    return {
      loadingBackdrop: false,
      rr: null as any,
      id: null,
      childAddInited: false,
      childFindLoading: false,
      findChildColumnsInitial: [
        { type: 'RadioButton', label: 'УЛС / ЕКШ', name: 'searchType', width: '50%', value: 0, params: [{ label: 'УЛС', value: 0 }, { label: 'ЕКШ', value: 1 }] },
        // value: 56327410,
        { type: 'Input', label: 'Внесите номер лицевого счета:', name: 'paymentKey', validation: 'required|number', width: '50%' },
        // value: '24.01.2014'
        { type: 'Input', label: 'Дата рождения', name: 'dateOfBirth', validation: 'dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ', width: '50%' }
      ],
      findChildColumns: [] as any,
      findChildColumnsValueSearchType: 0 as any,
      findChildApiObj: null as any,
      childFindInited: false,
      childFound: false,
      findInClassroomOpenModal: false,
      findInClassroomColumns: [
        {
          type: 'Select',
          label: 'Класс',
          name: 'placeId',
          params: {
            options: []
          },
          value: 0,
          validation: 'notNull'
        }
      ],
      studentsColumns: [
        { title: 'Фамилия', name: 'lastName' },
        { title: 'Имя', name: 'firstName' },
        { title: 'Отчество', name: 'middleName' },
        { title: '', name: 'edit', btn: true, btnText: '', btnIcon: 'check', btnClass: 'regular2', onClick: 'method', params: 'chooseStudent', width: '5%' }
      ],
      studentsLoading: false,
      students: [] as any,
      studentsTableFilters: [] as any,
      studentsTableFilterValueLetter: 'Все',
      findInClassroomFormResult: null as any,
      createOrUpdateChildColumnsInitial: [
        { type: 'Input', label: 'Фамилия', name: 'lastName', validation: 'required', width: '50%' },
        { type: 'Input', label: 'Имя', name: 'firstName', validation: 'required', width: '50%' },
        { type: 'Input', label: 'Отчество', name: 'middleName', validation: 'required', width: '50%' },
        { type: 'Input', label: 'Дата рождения', name: 'birthday', validation: 'dateAs:DD.MM.YYYY,ДД.ММ.ГГГГ', width: '50%' },
        // {
        //   type: 'Checkbox',
        //   label: 'Согласие на обработку персональных данных',
        //   name: 'agreePers',
        //   validation: 'req',
        //   width: '50%',
        //   value: false
        // },
        { type: 'Hidden', name: 'paymentKey' },
        { type: 'Hidden', name: 'regId' },
        { type: 'Hidden', name: 'id' },
        { type: 'Hidden', name: 'externalId' },
        { type: 'Hidden', name: 'login' },
        { type: 'Hidden', name: 'userPlaces' },
        { type: 'Hidden', name: 'userTypes' },
        { type: 'Hidden', name: 'customers' }
      ] as any,
      createOrUpdateChildColumns: [] as any,
      createOrUpdateChildApiObj: null as any
    }
  },
  mounted () {
    this.findChildColumns = this.findChildColumnsInitial
    const foundStep = this.getStoreStep('1-1')
    if (foundStep) {
      // console.log(foundStep)
      this.childFindInited = true
      this.childFound = true
      this.createOrUpdateChildColumns = f.addValuesToColumns(this.createOrUpdateChildColumnsInitial, foundStep.data)
    } else {
      this.createOrUpdateChildColumns = this.createOrUpdateChildColumnsInitial
    }
  },
  methods: {
    ...mapActions({
      loadTeachers: 'teachers/loadTeachersItems'
    }),
    findChildUpdateFormResult (formData) {
      this.findChildApiObj = formData
      const newTitle = (+formData.searchType > 0) ? 'Внесите номер карты школьника:' : 'Внесите номер лицевого счета:'
      const updatedForm = f.addValuesToColumns(this.findChildColumnsInitial, { paymentKey: newTitle }, 'label')
      this.findChildColumns = f.addValuesToColumns(updatedForm, this.findChildApiObj)
    },
    async findChildHandleSubmit () {
      this.loadingBackdrop = true
      this.childFindInited = true
      this.childFindLoading = true
      const obj: any = this.findChildApiObj
      try {
        let result = await findByPaymentKeyAndDateOfBirthApi(obj)
        // console.log(result)
        result = Object.assign({}, result)
        result.birthday = f.convertDate(result.birthday, 'DD.MM.YYYY')
        result = f.convertNameInObject(result)
        this.createOrUpdateChildColumns = f.addValuesToColumns(this.createOrUpdateChildColumnsInitial, result)
        if (result) {
          this.id = result.id
          // this.externalId = result.externalId
          // this.login = result.login
          // this.firstName = result.firstName
          // this.middleName = result.middleName
          // this.lastName = result.lastName
          // this.birthday = result.birthday
          // this.regId = result.regId
          // this.userTypes = result.userTypes
          // this.customers = result.customers
          // this.userPlaces = result.userPlaces
          if (result.lastName) {
            this.childFound = true
          }
          Swal.fire({ title: 'Пользователь найден', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        if ((error as any).data) {
          if ((error as any).data.errorCode === 404) {
            Swal.fire('', (error as any).data.errorMessage, 'warning') // custom error handling
          } else {
            Swal.fire('', (error as any).data.errorMessage, 'error')
          }
        }
        this.createOrUpdateChildColumns = this.createOrUpdateChildColumnsInitial
      } finally {
        this.childFindLoading = false
        this.loadingBackdrop = false
      }
    },
    async findInClassroomOnOpenModal () {
      this.getClassroomsApi().then((classrooms) => {
        this.findInClassroomColumns = f.addValuesToColumns(this.findInClassroomColumns, classrooms)
        this.findInClassroomOpenModal = true
      })
    },
    async getClassroomsApi () {
      let result
      try {
        result = await getClassroomsApi()
        if (Array.isArray(result) && result.length > 0) {
          const arr = result.map((el: any) => {
            return ({ value: el.id, label: el.name })
          })
          const obj = [{
            name: 'placeId',
            params: {
              options: [{ value: 0, label: 'Выберите класс' }, ...arr]
            }
          }]
          return obj
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        // console.log('classrooms ready')
      }
    },
    findInClassroomOnOkModal () {
      this.findInClassroomOpenModal = false
      // add smth
    },
    findInClassroomOnCloseModal () {
      this.findInClassroomOpenModal = false
    },
    findInClassroomFormResultUpdate (formData: any) {
      this.findInClassroomFormResult = formData
    },
    async getClassroomStudentsApi () {
      this.studentsLoading = true
      this.students = []
      let result
      const obj = {
        placeId: this.findInClassroomFormResult.placeId
      }
      try {
        result = await getClassroomStudentsApi(obj)
        if (result) {
          result = result.filter((el: any) => +el.typeId === 2)
          this.students = result.map((student: any) => {
            student = f.convertNameInObject(student)
            return student
          })
          this.buildStudentsTableFilters()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.studentsLoading = false
      }
    },
    buildStudentsTableFilters () {
      this.students.forEach((el: any) => {
        this.studentsTableFilters.push(el.lastName.charAt(0))
      })
      const uniqueLetters = this.removeDuplicates(this.studentsTableFilters)
      // console.log(uniqueLetters.sort())
      return uniqueLetters.sort()
    },
    removeDuplicates (arr: any) {
      const obj = {} as any
      const retArr = [] as any
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i]] = true
      }
      for (const key in obj) {
        retArr.push(key)
      }
      return retArr
    },
    initStudentsLetterFilter (letter: string) {
      this.studentsTableFilterValueLetter = letter
    },
    chooseStudent (item) {
      item.birthday = f.convertDate(item.birthday, 'DD.MM.YYYY')
      item = f.convertNameInObject(item)
      this.createOrUpdateChildColumns = f.addValuesToColumns(this.createOrUpdateChildColumnsInitial, item)
      this.childFindInited = true
      this.childFound = true
      this.findInClassroomOpenModal = false
    },
    createOrUpdateChildUpdateFormResult (data) {
      this.createOrUpdateChildApiObj = data
      // console.log(data)
    },
    async createOrUpdateChildHandleSubmit () {
      try {
        this.loadingBackdrop = true
        const requestObj = JSON.parse(JSON.stringify(this.createOrUpdateChildApiObj))
        const tempBirthday = JSON.parse(JSON.stringify(requestObj.birthday))
        requestObj.birthday = f.normDateFrom(requestObj.birthday, 'DD.MM.YYYY')
        const result = await ssuService.createUser(requestObj)
        console.log(result)
        if (result) {
          this.id = result.id
          this.$emit('idAway', result.id)
          this.childAddInited = true
          result.agreePers = true
          result.birthday = tempBirthday
          this.$store.dispatch('cart/addStepService', { step: '1-1', order: 1, data: result })
          this.$emit('nextStep')
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data ? (error as any).data.errorMessage : (error as any).message, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    ...mapState(['cart']),
    ...mapGetters({
      teachers: 'teachers/getTeachers'
    }),
    ...mapGetters({
      getStoreStep: 'cart/getStepService'
    }),
    steps (): void {
      return this.cart.stepsService
    },
    computedStudentsTableFilters (): any {
      const studentsTableFilters = [] as any
      this.students.forEach((el: any) => {
        const lastName = el.lastName.trim()
        if (lastName.length > 0) {
          studentsTableFilters.push(lastName.charAt(0))
        }
      })
      const uniqueLetters = this.removeDuplicates(studentsTableFilters)
      const sortedLetters = uniqueLetters.sort()
      sortedLetters.unshift('Все')
      return sortedLetters
    },
    computedStudents (): any {
      if (this.studentsTableFilterValueLetter === 'Все') {
        return this.students
      } else {
        return this.students.filter((el: any) => {
          return el.lastName.charAt(0) === this.studentsTableFilterValueLetter
        })
      }
    }
  },
  components: {
    FormBuilder,
    Modal,
    Table,
    LoadingSpinner,
    LoadingBackdrop
  }
})

