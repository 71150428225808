
































































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { getServicesAbonementsApi, removeAbonementItemApi, saveAbonementItemApi, addServiceApi } from '@/store/services/api-requests'
import ModalUiv from '@/components/ModalUiv.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'
import Table from '@/components/Table.vue'
import * as f from '@/services/sharedFunctions'
import Swal from 'sweetalert2'
import * as servicesService from '@/store/services/service'
import Button from '@/components/table/Button.vue'

export default Vue.extend({
  props: {
    routeEnterServiceTypeId: {
      required: false,
      type: [String, Number],
      default: 2
    }
  },
  data () {
    return {
      activeServiceType: 2,
      loadingBackdrop: false,
      abonements: [] as any,
      searchQuery: '',

      services: [],
      initialOpenModalConfig: [
        { type: 'Input', label: 'Название', name: 'name', validation: 'required' },
        { type: 'Input', label: 'Тариф (руб)', name: 'price', validation: 'required' },
        { type: 'Input', label: 'Кол-во зан.', name: 'countHours', validation: 'required' },
        { type: 'Textarea', label: 'Описание', name: 'description', validation: 'required' },
        { type: 'Hidden', name: 'id' },
        { type: 'Hidden', name: 'abonId' }
      ],
      openModal: false,
      openModalConfig: [] as any,
      modalItemId: null,
      modalTitle: ''
    }
  },
  mounted () {
    this.getServices()
    const type = this.storedActiveServiceType(this.$route.name)
    if (type) {
      this.activeServiceType = type
    }
  },
  methods: {
    async getServices () {
      try {
        await servicesService.loadAllServicesIfNone()
      } catch (error) {
        // Swal.fire('Ошибка', (error as any).error.message, 'error')
        Swal.fire({
          title: 'Ошибка',
          icon: 'error',
          text: (error as any).data.errorMessage,
          confirmButtonText: 'Перезагрузить',
          allowOutsideClick: false,
          backdrop: true,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            location.reload()
          }
        })
      }
    },
    filterServicesByServiceType (serviceType: number) {
      this.activeServiceType = serviceType
      this.$store.dispatch('navigation/addPageNavigation', { routeName: this.$route.name, data: this.activeServiceType })
    },
    goToEditPage (item?: any) {
      const routeName = (this.activeServiceType === 1)
        ? 'ServicesAbonementEdit' : (this.activeServiceType === 2)
          ? 'ServicesServiceType2Edit'
          : 'ServicesServiceType3Edit'
      if (item) {
        this.$router.push({ name: routeName, params: { id: item.id } })
      } else {
        this.$router.push({ name: routeName })
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    openModalEditAbonement (item: any) {
      this.modalItemId = item.abonId
      this.openModalConfig = this.buildDataForEdit(item)
      this.openModal = true
      this.modalTitle = 'Редактирование абонемента'
    },
    onClose () {
      this.openModal = false
      this.openModalConfig = []
      this.modalItemId = null
    },
    async onSave (item: any) {
      item.serviceId = item.id
      const postObj = { ...item, ...{ id: this.modalItemId } }
      postObj.price = f.currencyConvert(postObj.price, true)
      const storeObjAndPostObj = {
        storeObj: item,
        postObj: postObj
      }
      try {
        this.loadingBackdrop = true
        const result = await this.$store.dispatch('services/updateAbonementInService', storeObjAndPostObj)
        if (result) {
          this.onClose()
          Swal.fire({ title: 'Абонемент изменен', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    buildDataForEdit (item: any) {
      const itemArr = Object.keys(item).map((key) => ({ name: key, value: item[key] })) // convert obj to array
      return this.initialOpenModalConfig.map((el: any) => {
        const issetValues = itemArr.find((val: any) => {
          return val.name === el.name
        })
        if (issetValues) {
          el = { ...el, ...Object.assign({}, issetValues) }
        }
        return el
      })
    },
    async removeAbonementItem (item) {
      item.serviceId = item.id
      try {
        this.loadingBackdrop = true
        const result = await this.$store.dispatch('services/removeAbonementInService', item)
        if (result) {
          Swal.fire({ title: 'Абонемент удален', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loadingBackdrop = false
      }
    },
    buildAbonements (services: any) {
      services = JSON.parse(JSON.stringify(services))
      const resultServices = [] as any
      services = services.map((el: any) => {
        if (el.abonements.length > 0) {
          el.abonements = el.abonements.map((abon: any) => {
            if (!abon.removed) {
              return abon
            }
          })
        }
        if (!el.removed) {
          return el
        }
      })
      services.forEach(el => {
        el.abonName = ''
        el.isService = true
        resultServices.push(el)
        if (el.abonements.length > 0) {
          el.childcount = el.abonements.length + 1
          el.abonements.forEach(abon => {
            abon.abonName = abon.name
            abon.abonId = abon.id
            abon.id = el.id
            abon.isAbonement = true
            resultServices.push(abon)
          })
        }
      })
      return resultServices
    },
    async removeService (item) {
      if (Number(item.groupCount) > 0) {
        Swal.fire('', 'Данная услуга задействована в платной деятельности. Удалять нельзя', 'error')
      } else {
        try {
          this.loadingBackdrop = true
          const result = await this.$store.dispatch('services/removeService', item)
          if (result) {
            Swal.fire({ title: 'Услуга удалена', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        } finally {
          this.loadingBackdrop = false
        }
      }
    },
    serviceRemoveBtnClass (row) {
      let result = null as any
      if (row.isService) {
        if (Number(row.groupCount) > 0) {
          result = 'secondary'
        } else {
          result = 'danger'
        }
      } else {
        result = 'hide d-none'
      }
      return result
    }
  },
  computed: {
    ...mapGetters({
      servicesLoading: 'services/getLoading',
      servicesGetter: 'services/getServicesByServiceType',
      storedActiveServiceType: 'navigation/getPageNavigation',
      checkPermissions: 'user/checkPermissions'
    }),
    computedColumns (): any {
      let resultColumns = [] as any
      const serviceType1columns = [
        { title: 'Название', name: 'name', rowspan: 'id', btn: true, btnClass: 'link', onClick: 'method', params: 'goToEditPage', width: '25%' },
        { title: 'Абонементы', name: 'abonName' },
        { title: 'Цена', name: 'price', class: 'fzBigger' },
        { title: 'Кол-во', name: 'countHours', class: 'fzBigger' },
        { title: 'Сумма', name: 'totalPrice', class: 'fzBigger' },
        { title: 'Описание', name: 'description', width: '25%' },
        { title: 'Шаблон договора', name: 'templateName', width: '25%' },
        {
          title: '',
          name: 'editBtn',
          btns: true,
          class: 'btns btnsOne',
          width: '5%',
          btnsArr: [
            { btn: true, btnText: '', btnIcon: 'edit', btnClass: (row) => row.isAbonement ? 'primary' : 'hide d-none', onClick: 'method', params: 'openModalEditAbonement' },
            { btn: true, btnText: '', btnIcon: 'edit', btnClass: (row) => row.isService ? 'primary' : 'hide d-none', onClick: 'method', params: 'goToEditPage' }
          ]
        },
        {
          title: '',
          name: 'removeBtn',
          width: '5%',
          btns: true,
          class: 'btns btnsOne',
          btnsArr: [
            { btn: true, btnText: '', btnIcon: 'trash', btnClass: (row) => row.isAbonement ? 'danger' : 'hide d-none', onClick: 'method', params: 'removeAbonementItem' },
            { btn: true, btnText: '', btnIcon: 'trash', btnClass: (row) => this.serviceRemoveBtnClass(row), onClick: 'method', params: 'removeService' }
          ]
        }
      ]
      const serviceType2columns = [
        { title: 'Название', name: 'name', btn: true, btnClass: 'link', onClick: 'method', params: 'goToEditPage', width: '25%' },
        { title: 'Цена', name: 'price', class: 'fzBigger' },
        { title: 'Кол-во', name: 'countHours', class: 'fzBigger' },
        { title: 'Сумма', name: 'totalPrice', class: 'fzBigger' },
        { title: 'Описание', name: 'description', width: '25%' },
        { title: 'Шаблон договора', name: 'templateName', width: '25%' },
        { title: '', name: 'editBtn', text: '', btn: true, btnText: '', btnIcon: 'edit', btnClass: 'primary', onClick: 'method', params: 'goToEditPage', width: '5%' },
        { title: '', name: 'removeBtn', text: '', btn: true, btnText: '', btnIcon: 'trash', btnClass: 'danger', onClick: 'method', params: 'removeService', width: '5%' }
      ]
      if (+this.activeServiceType === 1) {
        resultColumns = serviceType1columns
      } else {
        resultColumns = serviceType2columns
      }
      if (this.checkPermissions([4])) {
        resultColumns = resultColumns.filter((col: any) => col.name !== 'removeBtn' && col.name !== 'editBtn')
      }
      return resultColumns
    },
    computedServices (): any {
      let result = this.servicesGetter(this.activeServiceType)
      if (+this.activeServiceType === 1) {
        result = this.buildAbonements(result)
      }
      return result
    }
  },
  components: {
    Table,
    ModalUiv,
    LoadingSpinner,
    LoadingBackdrop,
    Button
  }
})

