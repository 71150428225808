import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

const getContractsUrl = 'contracts'
const getContractUrl = 'contract/get'
const getContractNumberUrl = 'contract/getnumber'
const updateContractUrl = 'contract/update'
const getGroupContractsUrl = 'contracts/groupget'

export const getContractsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getContractsUrl, data)
  return result.data.data
}

export const getContractApi = async (data) => { // todo remove duplicate from cart api
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getContractUrl, data)
  return result.data.data
}

export const updateContractApi = async (data) => { // todo remove duplicate from cart api
  console.log(2)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateContractUrl, data)
  return result.data.data
}

export const getGroupContractsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getGroupContractsUrl, data)
  return result.data.data
}

export const getContractNumberApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getContractNumberUrl, data)
  return result.data.data
}
