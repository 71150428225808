































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import * as f from '@/services/sharedFunctions'

export default Vue.extend({
  props: [],
  mounted () {
    if (!this.user.person) {
      // this.$router.push({ path: 'login' })
    }
    setTimeout(() => {
      this.onChoose()
    }, 500)
  },
  data () {
    return {
      userRole: null,
      collapseState: 'fullheight',
      activeSection: 'Сервер учета услуг',
      clickedId: '' as string
    }
  },
  methods: {
    toggleClass (num: any) {
      if (isNaN(num)) {
        const parents = document.getElementsByClassName('parentItem')
        const parentsArr = Array.from(document.getElementsByClassName('parentItem'))
        parentsArr.forEach((el: any, i: number) => {
          if (el.id !== this.clickedId) {
            if (parents[i]) {
              parents[i].classList.remove('showNested')
            }
          }
        })
        setTimeout(() => {
          this.clickedId = ''
        }, 1000)
      } else {
        // console.log(num)
        const parent = document.getElementById('nestedTitles' + num.toString())
        this.catchClickedId('nestedTitles' + num.toString())
        if (parent) {
          if (parent.classList.contains('showNested')) {
            parent.classList.remove('showNested')
          } else {
            parent.classList.add('showNested')
          }
        }
      }
    },
    catchClickedId (elemId: string) {
      this.clickedId = elemId
    },
    onChoose () {
      // this.$scrollTo('#main-section-schools', 500, {})
      this.collapseState = 'condensed'
      const nav = document.getElementById('mainNav')
      if (nav) {
        nav.classList.add('condensed')
      }
      setTimeout(() => {
        this.activeSection = this.computedUserName
      }, 100)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      checkPermissions: 'user/checkPermissions'
    }),
    computedLogged (): any {
      if (this.user.person) {
        return this.user.person.login
      } else {
        return null
      }
    },
    computedUserName (): any {
      let result = '' as any
      if (this.checkPermissions([4])) {
        result = 'Преподаватель'
      } else {
        result = 'Администратор платных услуг'
      }
      return result
    },
    computedLinks (): any {
      let links = [
        {
          order: { role3: 1 },
          title: 'Запись ребенка на услугу',
          roles: [3],
          nested:
          [
            { title: 'Запись ребенка на услугу', routeName: 'ServiceSignUp', roles: [3] },
            { title: 'Запись списком детей', routeName: 'ServiceSignUpGroup', roles: [3] }
          ],
          class: 'sectPaid01'
        },
        {
          order: { role3: 20 }, title: 'Преподаватели', routeName: 'Teachers', roles: [3], class: 'sectPaid02'
        },
        {
          order: { role3: 30, role4: 20 }, title: 'Услуги', routeName: 'Services', roles: [3, 4], class: 'sectPaid03'
        },
        {
          order: { role3: 40, role4: 30 }, title: 'Расписание', routeName: 'Groups', roles: [3, 4], class: 'sectPaid04'
        },
        {
          order: { role3: 49, role4: 39 }, title: 'Календарно-тематическое планирование', routeName: 'CalendarPlanning', roles: [1, 3, 4], class: 'sectPaid09'
        },
        {
          order: { role3: 50, role4: 40 }, title: 'Наполнение групп', routeName: 'StudentsByGroups', roles: [3, 4], class: 'sectPaid05'
        },
        {
          order: { role3: 60, role4: 50 },
          title: 'Начисление оплаты обучения',
          roles: [3, 4],
          nested:
          [
            { title: 'Начисления', routeName: 'GroupsPaid', roles: [3, 4] },
            { title: 'Квитанции', routeName: 'ReceiptCartulary', roles: [3] },
            { title: 'Договоры', routeName: 'Contracts', roles: [3] },
            { title: 'Должники', routeName: 'Debtors', roles: [3] }
          ],
          class: 'sectPaid06'
        },
        {
          order: { role3: 80, role4: 1 },
          title: 'Табель посещения',
          roles: [3, 4],
          nested:
          [
            { title: 'Табель посещения', routeName: 'AttendanceSheet', roles: [3, 4] },
            { title: 'Непосещения', routeName: 'NonAttendance', roles: [3] }
          ],
          class: 'sectPaid08'
        },
        {
          order: { role3: 100 }, title: '1С', routeName: 'Home', roles: [3], class: 'sectPaid07'
        }
      ] as any

      if (this.checkPermissions([4])) {
        links = links.map((el: any) => {
          el.userOrder = el.order.role4 ? el.order.role4 : 99
          return el
        })
      } else {
        links = links.map((el: any) => {
          el.userOrder = el.order.role3 ? el.order.role3 : 99
          return el
        })
      }
      return f.orderBy(links, 'userOrder', 'desc')
    }
  },
  components: {
    //
  }
})

