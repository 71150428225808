






















import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
type BaseData = {
  id: string;
  valueModel: any;
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4(),
      valueModel: null
    }
  },
  props: {
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    params: {
      required: true,
      type: [Array, Object]
    }
  },
  methods: {
    update (event: Event) {
      const target = event.target as HTMLTextAreaElement
      this.$emit('input', target.value)
      // console.log(target.value)
    }
  },
  computed: {
    options (): void {
      return this.params.options
    }
  },
  mounted () {
    this.valueModel = this.value
    this.$emit('input', this.value)
  }
})
