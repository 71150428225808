

















import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import Button from '@/components/table/Button.vue'
type BaseData = {
  id: string;
  valueModel: string | number | boolean | null;
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4(),
      valueModel: null
    }
  },
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    labelPosition: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: [String, Number, Boolean]
    },
    params: {
      required: false,
      type: Object
    },
    propId: { // todo delete?
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    //
  },
  watch: {
    value (val1, val2) {
      this.valueModel = val1
    }
  },
  mounted () {
    this.valueModel = this.value
    this.$emit('input', this.value)
  },
  components: {
    Button
  }
})
