import { createContractApi } from '@/store/cart/api-request'
import { createContractPrivateApi } from '@/store/privateData/api-requests'
import * as f from '@/services/sharedFunctions'
import store from '@/store'

export const createContract = async (data: any, privateData: any) => {
  // console.log(data)
  let parent = ''
  parent += data.lastName ? data.lastName : ''
  parent += data.firstName ? (' ' + data.lastName) : ''
  parent += data.middleName ? (' ' + data.middleName) : ''
  data.parent = parent !== '' ? parent : ''
  let passport = ''
  passport += data.tempPassportSeriaWithNumber ? f.convertStringToNumbers(data.tempPassportSeriaWithNumber) : ''
  passport += data.tempPassportCode ? (' ' + f.convertStringToNumbers(data.tempPassportCode)) : ''
  passport += data.tempPassportDateWithOrg ? (' ' + data.tempPassportDateWithOrg) : ''
  data.passport = passport !== '' ? passport : ''
  data.number = data.number.toString()
  data.phone = data.phone ? f.convertStringToNumbers(data.phone) : null

  const getPdType = store.getters['user/getPdType']
  if (getPdType === 1) {
    if (store.getters['privateData/getAccessDenied']) {
      return Promise.reject(new Error('Доступ к программе запрещен'))
    } else {
      const sliced = { ...data }
      sliced.lastName = sliced.lastName ? sliced.lastName.slice(0, 1) + '. ' : null
      sliced.parent = null
      sliced.passport = null
      const result1 = await createContractApi(sliced)
      if (result1) {
        privateData.contractId = result1.id
        privateData.phoneNumber = privateData.phone
        privateData.seriesAndNumber = privateData.tempPassportSeriaWithNumber
        privateData.divisionCode = privateData.tempPassportCode
        privateData.issuedBy = privateData.tempPassportDateWithOrg
        const result2 = await createContractPrivateApi(privateData)
        if (result2) {
          result1.lastName = data.lastName
          return result1
        }
      }
    }
  } else {
    return await createContractApi(data)
  }
}
