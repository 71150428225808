





















































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import { findByPaymentKeyAndDateOfBirthApi, getClassroomsApi, getClassroomStudentsApi, createUserApi } from '@/store/servicesSignUp/api-requests'
import { getGroupsByServiceIdApi } from '@/store/groups/api-requests'
import { addToCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import FormBuilder from '@/components/form/FormBuilder.vue'
import CartList from '@/pages/cart/CartList.vue'
import moment from 'moment'
import Modal from '@/components/Modal.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'
import Step1 from '@/pages/serviceSignUp/Step1.vue'
import Step2 from '@/pages/serviceSignUp/Step2.vue'
import Step3 from '@/pages/serviceSignUp/Step3.vue'
import Step4 from '@/pages/serviceSignUp/Step4.vue'
import UserBlock from '@/pages/serviceSignUp/UserBlock.vue'
import Button from '@/components/table/Button.vue'
import ProgramAuthModal from '@/components/main/ProgramAuthModal.vue'

export type FindChildApiType = {
  paymentKey: number;
  dateOfBirth: string;
}

export default Vue.extend({
  name: 'src-pages-login-login',
  props: ['initUserId'],
  mounted () {
    // console.log(this.$route.params.initUserId)
    if (this.$route.params.initUserId) {
      this.assignId(Number(this.$route.params.initUserId))
      this.goToStep(2)
    }
  },
  data () {
    return {
      // 1
      // paymentKey: 56327410,
      // dateOfBirth: '24.01.2014',
      //
      id: null,
      externalId: null,
      login: null,
      firstName: null,
      middleName: null,
      lastName: null,
      birthday: null,
      regId: null,
      userTypes: null,
      customers: null,
      userPlaces: null,
      agreePers: false,
      childAddInited: false,
      showCart: false,
      // 2
      showChooseServiceType: true,
      serviceTypeChosen: '0',
      // dateFrom: '2021-10-20',
      // dateTo: '2022-10-20',
      // 4
      searchQuery: null,
      step: 1
    }
  },
  methods: {
    assignId (id: any) {
      this.id = id
    },
    ...mapActions('user', { userLogin: 'login' }),
    goToStep (step: number) {
      // console.log('step :' + step)
      // if (Number(this.step) > Number(step)) {
      // }
      this.step = step
      setTimeout(() => {
        this.$store.dispatch('cart/removeFutureStepsService', step)
      }, 0)
    },
    goToStep1 (step?: any) {
      //
    },
    cancel () {
      this.$store.dispatch('cart/clearState').then((res: any) => {
        if (res) {
          this.goToStep(1)
        }
      })
    },
    getStepNavItemStatus (itemStep, currentStep) {
      if (Number(itemStep) === Number(currentStep)) {
        return 'active'
      } else if (Number(currentStep) - Number(itemStep) > 0) {
        return 'done'
      }
    },
    reloadThisStep (step) {
      this.step = 0
      setTimeout(() => {
        this.step = step
      }, 0)
    }
  },
  computed: {
    ...mapState(['cart']),
    cartItems (): void {
      return this.cart.cart
    },
    ...mapGetters({
      getStoreStep: 'cart/getStepService'
    })
  },
  components: {
    // Table,
    CartList,
    // FormBuilder,
    // Modal,
    // StepsResult,
    Step1,
    Step2,
    Step3,
    Step4,
    UserBlock,
    Button
    // ProgramAuthModal
  }
})

