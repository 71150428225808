











































































import Vue from 'vue'
import TdContent from '@/components/table/TdContent.vue'
import * as f from '@/services/sharedFunctions'
export type BaseData = {
  sortOrders: any;
  sortKey: any;

}
export default Vue.extend({
  props: {
    items: Array,
    columns: Array,
    filterKey: String,
    noResult: String,
    sortByKeyObj: Object,
    rowClass: [String, Function]
  },
  data (): BaseData {
    const sortOrders: any = {}
    if (Array.isArray(this.columns)) {
      this.columns.forEach((key: any) => {
        sortOrders[key.name] = 1
      })
    }
    return {
      sortKey: '',
      sortOrders: sortOrders
    }
  },
  computed: {
    filteredItems (): any {
      const sortKey = this.sortKey
      const filterKey = this.filterKey && this.filterKey.toLowerCase()
      // console.log(this.sortOrders)
      const order = this.sortOrders[sortKey] || 1
      let items = this.items
      if (filterKey) {
        items = items.filter(function (row: any) {
          return Object.keys(row).some(function (key) {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            )
          })
        })
      }
      if (sortKey) {
        items = items.slice().sort(function (a: any, b: any) {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      return items
    }
  },
  filters: {
    capitalize (str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  watch: {
    sortByKeyObj (newVar, oldVar) {
      if (newVar !== null && newVar !== undefined) {
        this.sortByObj(newVar)
      }
    }
  },
  methods: {
    thFilterClasses (classStr: string) {
      return f.classesFilter(['displayNone', 'displayTableCell', 'bgColor'], classStr)
    },
    assignRowspan (list: any[], index: number, rowspanProp: any, rowspanPropValue: any) {
      let rowspan = 1
      for (let i = index; i + 1 < list.length; i++) { // loop thru next elements (rows)
        if (i + 1 < list.length) {
          if (list[i + 1][rowspanProp] === rowspanPropValue && !list[i + 1].removed) {
            rowspan++
          } else {
            break
          }
        }
      }
      return rowspan
    },
    // assignColSpan
    sortByObj (sortByObj: any) {
      this.sortKey = sortByObj.value
      this.sortOrders[sortByObj.value] = sortByObj.direction
      this.$emit('sortKeyAway', { key: sortByObj.value, value: this.sortOrders[sortByObj.value] })
    },
    sortBy (key: string) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
      this.$emit('sortKeyAway', { key: key, value: this.sortOrders[key] })
    },
    // onClick (onClick: string, params: any, item: any) {
    //   if (onClick === 'path') {
    //     this.$router.push({ path: `${params.path}/${item[params.prop]}` })
    //   } else if (onClick === 'action') {
    //     this.dispatch(params, item)
    //   }
    // },
    dispatch (action: string, data?: any) {
      this.$store.dispatch(action, data)
    }
  },
  mounted () {
    //
    // console.log(this.noResult)
  },
  components: {
    TdContent
  }
})

