















































import Vue from 'vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import ModalUiv from '@/components/ModalUiv.vue'
import Modal from '@/components/Modal.vue'
import { removeAbonementItemApi } from '@/store/services/api-requests'
import DatePickr from '@/components/form/DatePickr.vue'
import * as f from '@/services/sharedFunctions'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as teacherService from '@/store/teachers/service'
import Swal from 'sweetalert2'
import TimePickr from '@/components/form/TimePickr.vue'
import Button from '@/components/table/Button.vue'
import Select from '@/components/form/Select.vue'
import moment from 'moment'

export default Vue.extend({
  props: {
    datesSettings: {
      required: false,
      type: Array,
      default () {
        return []
      }
    },
    // watchDatesSettings: {
    //   required: false,
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // },
    showDayContent: {
      required: false,
      type: Boolean,
      default () {
        return false
      }
    },
    inactivePastDates: {
      required: false,
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    const defaultTime = ['12:00', '13:00']
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    return {
      // tempDatesSettings: [] as any,
      defaultTime: defaultTime as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: [{ label: '2022', value: 2022 }, { label: 2023, value: 2023 }]
      },
      currentMonthInNumber: new Date().getMonth() as any,
      currentYear: new Date().getFullYear() as any,
      days: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      filterObj: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      selectedDate: null as any
    }
  },
  watch: {
    datesSettings (newVal, oldVal) {
      // console.log(newVal)
    }
  },
  mounted () {
    // console.log(this.showDayContent)
  },
  methods: {
    async dayClickAway (day: number) {
      const selectedDate = this.composeFullDate(day)
      if (this.inactivePastDates) {
        if (moment(selectedDate) < moment(new Date())) {
          Swal.fire({ title: 'Запрещено менять на прошедшую дату', icon: 'warning', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 3500 })
        } else {
          this.selectedDate = selectedDate
          this.$emit('dayClick', selectedDate)
        }
      } else {
        this.$emit('dayClick', selectedDate)
      }
    },
    findDateInArr (date: any) {
      const arr = JSON.parse(JSON.stringify(this.computedDatesSettings))
      const foundDate = arr.find((el: any) => {
        return el.date === date
      })
      return foundDate
    },
    isRemovedDate (date: any) {
      date = this.composeFullDate(date)
      const foundDate = this.findDateInArr(date)
      if (foundDate && foundDate.removed) {
        return true
      } else {
        return false
      }
    },
    assignSettingsToCalendarDate (date: any) {
      date = this.composeFullDate(date)
      const foundDate = this.findDateInArr(date)
      if (foundDate) {
        const time1 = foundDate.timeFrom && foundDate.timeFrom.length === 8 ? foundDate.timeFrom.slice(0, -3) : foundDate.timeFrom
        const time2 = foundDate.timeTo && foundDate.timeTo.length === 8 ? foundDate.timeTo.slice(0, -3) : foundDate.timeTo
        return time1 + '-' + time2
      } else {
        return null
      }
    },
    composeFullDate (date) {
      return this.currentYear + '-' + f.prependZero(+this.currentMonthInNumber + 1) + '-' + f.prependZero(date)
    },
    updateMonth (data: any) {
      // console.log(data)
      this.currentMonthInNumber = Number(data) - 1
    },
    updateYear (data: any) {
      // console.log(data)
      this.currentYear = Number(data)
    },
    daysInMonth (year, month) {
      return new Date(year, month + 1, 0).getDate()
    },
    startDay () {
      let date = new Date(this.currentYear, this.currentMonthInNumber, 1).getDay() - 1
      if (date === -1) {
        date = 6
      }
      return date
    },
    nextMonth () {
      if (this.currentMonthInNumber === 11) {
        this.currentYear++
        this.currentMonthInNumber = 0
        this.filterObj.year = this.currentYear
      } else {
        this.currentMonthInNumber++
      }
      this.filterObj.month = this.currentMonthInNumber + 1
    },
    prevMonth () {
      if (this.currentMonthInNumber === 0) {
        this.currentYear--
        this.currentMonthInNumber = 11
        this.filterObj.year = this.currentYear
      } else {
        this.currentMonthInNumber--
        this.filterObj.month = this.currentMonthInNumber + 1
      }
    }
  },
  computed: {
    currentMonthInName (): any {
      return new Date(this.currentYear, this.currentMonthInNumber).toLocaleString('default', { month: 'long' })
    },
    computedMonth (): any {
      return this.selectMonthParams
    },
    computedYear (): any {
      return this.selectYearParams
    },
    computedDatesSettings (): any {
      // console.log(this.datesSettings)
      return this.datesSettings
    }
  },
  components: {
    Button,
    Select
  }
})
