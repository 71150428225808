import axios, { setCustomErrorHandling } from '@/services/axios'
import { AxiosResponse } from 'axios'

const addToCartUrl = 'cart/create'
const getCartItemsUrl = 'carts'
const removeFromCartUrl = 'cart/remove'
const createContractUrl = 'contract/create'
const contractGetnumberUrl = 'contract/getnumber' // todo replace to contract requests
const registerPrePaidSumUrl = 'document/receipt'
const contractFinishUrl = 'contract/finish'
const cartRemoveallUrl = 'cart/removeall'
const contractGetUrl = 'contract/get' // todo replace to contract requests
const contractUpdateUrl = 'contract/update' // -"-
const usergroupRemoveUrl = 'usergroup/remove'

export const addToCartApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(addToCartUrl, data)
  return result.data.data
}

export const getCartItemsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getCartItemsUrl, data)
  return result.data.data
}

export const removeFromCartApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(removeFromCartUrl, data)
  return result.data.data
}
/**
 * Оформление договора
 */
export const createContractApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createContractUrl, data)
  return result.data.data
}

export const contractGetNumberApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(contractGetnumberUrl)
  return result.data.data
}

/**
 * Регистрируем сумму оплаты
 */
export const registerPrePaidSumApi = async (data) => {
  // setCustomErrorHandling(axios)
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(registerPrePaidSumUrl, data)
  return result.data.data
}

/**
 * Удалить все из корзины
 */
export const cartRemoveallApi = async (userId) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(cartRemoveallUrl, { userId: userId })
  return result.data.data
}

// FINISH
export const contractFinishApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(contractFinishUrl, data)
  return result.data.data
}

export const contractGetApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(contractGetUrl, data)
  return result.data.data
}

export const contractUpdateApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(contractUpdateUrl, data)
  return result.data.data
}

export const usergroupRemoveApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(usergroupRemoveUrl, data)
  return result.data.data
}
