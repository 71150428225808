



























































































































































































import Vue from 'vue'
import { getStudentsByGroupsApi, getGroupsByServiceIdApi, getGroupsWithReceiptsApi, updateDaysInGroupRowApi, createReceiptsByGroupsApi } from '@/store/groups/api-requests'
import Table from '@/components/Table.vue'
import Select from '@/components/form/Select.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import * as groupService from '@/store/groups/service'
import * as serviceService from '@/store/services/service'
import * as teacherService from '@/store/teachers/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Modal from '@/components/Modal.vue'
import MoveLessonModal from '@/pages/nonAttendance/MoveLessonModal.vue'
import Label from '@/components/form/Label.vue'
import Button from '@/components/table/Button.vue'
import FileLoad from '@/pages/nonAttendance/FileLoad.vue'
import { usergroupvisitAbsentsApi, usergroupvisitRefundApi } from '@/store/attendanceSheet/api-requests'
import axios, { AxiosInstance } from 'axios'
import DateRangePicker from '@/components/form/DateRangePicker.vue'

export default Vue.extend({
  props: [],
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    return {
      moveLessonPropItem: {} as any,
      moveLessonModalOpen: false,
      auth: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZjhhNjkxOTc5ODc0YjBmZThkYjEyZTQ4MzQyZDRkNjllZjk5MmFmOTQ4ZmVkZGFmZTE1MmUwYjJmOTlhZmM1YjZiMTAzOGE1Y2YwMThlZjkiLCJpYXQiOjE2NDM4MDc0MTcuNDE5ODYyLCJuYmYiOjE2NDM4MDc0MTcuNDE5ODczLCJleHAiOjE2NzUzNDM0MTcuMzkyMDE2LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.lp_XKhVHDR9BFMw-IpN2vcxEtuKaPV9GQi4ipcjtvniSLVMhKqUbpyzwSoliQMfGTYJikDxyDLtCKKYBKPcuMjnbEKnb7bbP8-KSCkSVaTQvoFMuY5bU3JCTjterJIOu_SWDEjNDEBrrrRSOoXUChOi8Cq7ugk-2RcqHnmq4_ZPf2A9Y6e1HtW4iFBw3PJVAqvbn5Ufj2mBjDkSQ_M5j5Pa0Mwi5gpKkSXK5Z46AfI-FjXtiz_J0JCXvBaRvjyFpZfZj0nxQlK7dAR8WjAoE63NuSL7lz4ww8ASzR25OkyGMRx3Ky4I_koEOfQm_fscNCWJD6jUAF7YBNAohZrOL1ktgFoundRPGnhCHxaORbtvKNrWuLDuUIkNUNtOmG6VY8GNqdYHKHpq5toXp7fTvbPaGdcb-mLY_71OMF7o2XaMHo8xdzI3xr73YVrRoa9u3_bLxHijWmRKYJTWD2rDBdcGzWwGR8rzU22EisopS69vmObHYnYr8gTteDzB6L8ujOWdhJt4keE9Wp6a--K6YrZ1uz_X7YJ9CUcdYtuFVY7kMl9vLhiIBTJzf-v8egne02dhROLoaJ2-IikiV1q2595PMByX41ktYeWU_IjLziozUh1uis9kRpo4MJTnmiZawJidMsGVlerE3NlxYwREReJSxA1LkzLBZXAIt1Efkm50',
      absents: [] as any,
      servicesAll: [] as any,
      datesAll: [] as any,
      groupsAll: [] as any,
      addScanModalChildInfo: [] as any,
      addScanModalOpen: false,
      allChildNames: [] as any,
      selectedRows: [] as any,
      loading: false,
      groups: [] as any,
      filterObj: {
        serviceId: '0',
        teacherId: '0',
        childName: '0',
        groupId: '0',
        date: {
          startDate: null,
          endDate: null
        },
        reason: 'all'
      } as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: [
          { label: 2021, value: 2021 },
          { label: 2022, value: 2022 }
        ]
      },
      getGroupsByDateApiObject: {
        month: moment().month() + 1,
        year: moment().year()
      } as any
    }
  },
  watch: {
    filterObj: {
      handler (newVal) {
        // console.log(newVal)
      },
      deep: true
    }
  },
  mounted () {
    if (this.$route.query.propMonth) {
      this.getGroupsByDateApiObject.month = Number(this.$route.query.propMonth)
    }
    if (this.$route.query.propYear) {
      this.getGroupsByDateApiObject.year = Number(this.$route.query.propYear)
    }
    this.getAbsents()
  },
  methods: {
    loadFinished () {
      this.selectedRows = []
      this.addScanModalOnClose()
      this.getAbsents()
    },
    moveLessonModalOnSave (data: any) {
      console.log(data)
    },
    moveLessonModalOnClose () {
      this.moveLessonModalOpen = false
    },
    moveLessonModalOnOpen () {
      this.moveLessonModalOpen = true
    },
    async moveLesson (item) {
      // console.log(item)
      this.moveLessonPropItem = item
      this.moveLessonModalOnOpen()
    },
    async refund (item?: any) {
      const apiData = [] as any
      if (item) {
        apiData.push({ id: item.id })
      } else {
        this.selectedRows.forEach((el: any) => {
          apiData.push({ id: el })
        })
      }
      try {
        const result = await usergroupvisitRefundApi({ userGroupVisits: apiData })
        if (result) {
          Swal.fire({ title: 'Успешно', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 4500 })
          this.getAbsents()
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    addScanModalOnOpen () {
      this.addScanModalOpen = true
      // this.arrToUpload = this.absents.filter((el: any) => this.selectedRows.some(id => id === el.id))
    },
    addScanModalOnClose () {
      this.addScanModalOpen = false
    },
    addScanModalOnSave (data: any) {
      console.log(data)
    },
    filterGroupsByReason (val: number) {
      this.filterObj.reason = val
    },
    async getAbsents () {
      this.absents = []
      // this.filterObj = {
      //   serviceId: '0',
      //   teacherId: '0',
      //   childName: '0',
      //   groupId: '0',
      //   date: '0',
      //   reason: 'all'
      // }
      try {
        this.loading = true
        const result = await usergroupvisitAbsentsApi()
        if (result) {
          serviceService.loadAllServicesIfNone().then((services) => {
            this.absents = result[0].absents.map((absense: any, i: number) => {
              this.datesAll.push({ label: absense.date, value: absense.date })
              if (absense.userGroup) {
                if (absense.userGroup.user) {
                  absense.childName = f.getLastNameAndInitialsFromObject(absense.userGroup.user.userDetail)
                  this.allChildNames.push({ label: absense.childName, value: absense.childName })
                }
                if (absense.userGroup.group) {
                  absense.groupName = absense.userGroup.group.name
                  absense.groupId = absense.userGroup.group.id
                  const foundService = services.find((el: any) => +el.id === +absense.userGroup.group.serviceId)
                  if (foundService) {
                    this.servicesAll.push(foundService)
                    absense.serviceName = foundService.name
                    absense.serviceId = foundService.id
                  }
                  const foundGroup = foundService.groups.find((el: any) => +el.id === +absense.userGroup.group.id)
                  if (foundGroup) {
                    this.groupsAll.push({ label: foundGroup.name, value: foundGroup.id })
                    absense.teacherName = foundGroup.teacherName
                    absense.teacherId = foundGroup.teacherId
                  }
                }
              }
              if (absense.userGroupVisitCorrections && Array.isArray(absense.userGroupVisitCorrections) && absense.userGroupVisitCorrections.length) {
                const ids = absense.userGroupVisitCorrections.map(el => el.id)
                const maxId = Math.max(...ids)
                const foundLastCorrection = absense.userGroupVisitCorrections.find((el: any) => el.id === maxId)
                absense.scan = `${process.env.VUE_APP_URL}` + foundLastCorrection.file.slug
                absense.correctionType = foundLastCorrection.correctionType
                // if (i === 2) { // todo
                //   absense.correctionType = 2
                // }
                absense.reason = 1
              } else {
                absense.reason = 0
              }
              return absense
            })
            this.loading = false
          })
        } else {
          Swal.fire('', 'По вашему запросу данных не обнаружено', 'info')
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    updateMonth (month: number) {
      // console.log(month)
      this.getGroupsByDateApiObject.month = month
    },
    updateYear (year: number) {
      // console.log(year)
      this.getGroupsByDateApiObject.year = year
    },
    async filterGroupsByDate () {
      // this.getGroups(this.getGroupsByDateApiObject)
      this.filterObj = {
        serviceId: '0',
        teacherId: '0'
      }
    },
    updateService (serviceId: any) {
      this.filterObj.serviceId = serviceId
      if (this.filterObj.teacherId !== '0') {
        this.filterObj.teacherId = '0'
      }
      this.filterObj.groupId = '0'
      this.filterObj.childName = '0'
    },
    updateTeacher (teacherId: any) {
      this.filterObj.teacherId = teacherId
      this.filterObj.childName = '0'
    },
    updateChildName (name: any) {
      this.filterObj.childName = name
    },
    updateGroup (groupId: any) {
      this.filterObj.groupId = groupId
      this.filterObj.teacherId = '0'
      this.filterObj.childName = '0'
    },
    updateDateFilter (date: string) {
      this.filterObj.date = date
    },
    addRowToSelected (item: any) {
      if (item.checkbox) {
        this.selectedRows.push(item.id)
        let dates = this.absents.filter((el: any) => this.selectedRows.some(id => id === el.id))
        dates = dates.map((el: any) => new Date(el.date).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' }))
        this.addScanModalChildInfo = [
          { label: 'ФИО', value: item.childName },
          { label: 'Группа', value: item.groupName },
          { label: 'Услуга', value: item.serviceName },
          { label: 'Даты', value: dates.join(', ') }
        ]
      } else {
        const index = this.selectedRows.indexOf(Number(item.id))
        if (index > -1) {
          this.selectedRows.splice(index, 1)
        }
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    /**
     * галочки у Уважительных неактивны
     * активны становятся когда выбрали вверху Уважительные: filterObj.reason === 1
     * Если выбрана одна фамилия, то еще можно выбрать только ту же фамилию.
     */
    setCheckboxDisableState (row: any) {
      let result = false
      if (this.selectedRows.length) {
        const foundCheckedItem = this.absents.find((el: any) => el.id === this.selectedRows[0])
        if (foundCheckedItem) {
          if (foundCheckedItem.childName !== row.childName) {
            result = true
          }
          if (foundCheckedItem.reason !== row.reason) {
            result = true
          }
        }
      }
      if (row.reason === 1 && this.filterObj.reason !== 1) {
        result = true
      }
      if (this.filterObj.reason === 1 && row.correctionType !== 0) { // У Уважительных галочки активны только с correctionType = 0
        result = true
      }
      return result
    },
    docWindowOpen (item: any) {
      if (item.scan) {
        window.open(item.scan)
      } else {
        Swal.fire('Ошибка', 'Проблема со ссылкой', 'error')
      }
    },
    noAction (item: any) {
      console.log(item, 0)
    },
    noAction1 (item: any) {
      console.log(item, 1)
    },
    openOneScanModal (item: any) {
      this.addScanModalChildInfo = [
        { label: 'ФИО', value: item.childName },
        { label: 'Группа', value: item.groupName },
        { label: 'Услуга', value: item.serviceName },
        { label: 'Даты', value: new Date(item.date).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' }) }
      ]
      this.selectedRows = []
      this.selectedRows.push(item.id)
      this.addScanModalOnOpen()
    },
    nn (prop: any) {
      let result = true
      if (!prop && prop !== 0) {
        result = false
      }
      return result
    }
  },
  computed: {
    ...mapGetters({
      checkPermissions: 'user/checkPermissions',
      getAllServicesGroups: 'services/getAllServicesGroups'
    }),
    batchActionBtnState (): any {
      return this.filterObj.reason === 1 ? 1 : 0
    },
    groupsColumns (): any {
      return [
        { title: '', name: 'checkbox', width: '30px', type: 'Checkbox', onClick: 'method', params: 'addRowToSelected', disabled: (row) => this.setCheckboxDisableState(row) },
        { title: 'ФИО', name: 'childName', width: '50px' },
        { title: 'Услуга', name: 'serviceName', width: '40px' },
        { title: 'Группа', name: 'groupName', width: '' },
        { title: 'Преподаватель', name: 'teacherName' },
        { title: 'Дата', name: 'date', width: '50px', text: (row) => new Date(row.date).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' }) },
        {
          title: 'Причина',
          name: 'reason',
          text: (row) => row.reason ? 'Уважительная' : 'Не уважительная'
          // btns: true,
          // class: 'btns',
          // btnsArr: [
          //   { btn: true, btnText: 'Уважительная', btnClass: (row) => row.reason ? 'link' : 'hide d-none', onClick: 'method', params: 'docWindowOpen' }
          // ]
        },
        // { title: 'Сумма к возврату', name: 'returnSum', width: '50px', class: 'fzBigger' }
        {
          name: 'actions',
          btns: true,
          text: (row) => row.correctionType === 1 ? 'Возвращены средства' : row.correctionType === 2 ? 'Занятие перенесено' : null,
          class: 'btns',
          classCondition: (row) => row.correctionType === 1 || row.correctionType === 2 ? 'btns btnsReversed' : 'btns',
          btnsArr: [
            { btn: true, mdiIcon: true, btnText: '', btnTitle: 'Оформить возврат', btnIcon: 'cash-refund', btnClass: (row) => row.correctionType === 0 ? 'primary' : 'hide d-none', onClick: 'method', params: 'refund' },
            { btn: true, btnText: '', btnTitle: 'Перенести занятие', btnIcon: 'exchange', btnClass: (row) => row.correctionType === 0 ? 'primary' : 'hide d-none', onClick: 'method', params: 'moveLesson' },
            { btn: true, btnText: '', btnTitle: 'Открыть скан справки', btnIcon: 'file-text-o', btnClass: (row) => row.correctionType === 0 || row.correctionType === 1 || row.correctionType === 2 ? 'primary' : 'hide d-none', onClick: 'method', params: 'docWindowOpen' },
            { btn: true, btnText: 'Добавить документ', btnTitle: 'Добавить скан справки', btnIcon: '', btnClass: (row) => !row.reason ? 'link' : 'hide d-none', onClick: 'method', params: 'openOneScanModal' }
          ]
        }
      ]
    },
    computedServices (): any {
      const options = [] as any
      this.servicesAll.forEach((service: any) => {
        options.push({
          label: service.name,
          value: service.id
        })
      })
      const options2 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Выберите услугу', value: 0 }, ...options2]
      }
    },
    computedTeachers (): any {
      const filteredArray = JSON.parse(JSON.stringify(this.computedAbsents))
      let options = [] as any
      filteredArray.forEach((group: any) => {
        options.push({
          label: group.teacherName,
          value: group.teacherId
        })
      })
      options = options.filter((el: any) => el.value)
      const options1 = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Выберите преподавателя', value: 0 }, ...options1]
      }
    },
    computedAbsents (): any {
      let filteredArray = this.absents
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.serviceId) === Number(this.filterObj.serviceId)
        })
      }
      if (this.filterObj.groupId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          console.log(el.groupId)
          return +el.groupId === +this.filterObj.groupId
        })
      }
      if (this.filterObj.teacherId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.teacherId) === Number(this.filterObj.teacherId)
        })
      }
      if (this.filterObj.childName !== '0') {
        filteredArray = filteredArray.filter((el: any) => el.childName === this.filterObj.childName)
      }
      if (this.filterObj.reason !== 'all') {
        filteredArray = filteredArray.filter((el: any) => +el.reason === +this.filterObj.reason)
      }
      if (this.filterObj.date !== '0') {
        if (this.filterObj.date.startDate && this.filterObj.date.endDate) {
          filteredArray = filteredArray.filter((el: any) => {
            const date = moment(el.date)
            return date.isSameOrAfter(this.filterObj.date.startDate) && date.isSameOrBefore(this.filterObj.date.endDate)
          })
        }
      }
      return filteredArray
    },
    computedGroupsFilter (): any {
      let filteredArray = JSON.parse(JSON.stringify(this.absents))
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.serviceId) === Number(this.filterObj.serviceId)
        })
      }
      let options = [] as any
      filteredArray.forEach((absense: any) => {
        options.push({ label: absense.groupName, value: absense.groupId })
      })
      options = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Выберите группу', value: 0 }, ...options]
      }
    },
    computedDatesFilter (): any {
      const filteredArray = JSON.parse(JSON.stringify(this.computedAbsents))
      const moments = filteredArray.map(absense => moment(absense.date))
      const maxDate = moment.max(moments)
      const minDate = moment.min(moments)
      return { minDate: minDate.format('YYYY-MM-DD'), maxDate: maxDate.format('YYYY-MM-DD') }
    },
    computedReasons (): any {
      return {
        options: [{ label: 'Выберите', value: 0 }, { value: 1, label: '14.03.2022' }]
      }
    },
    computedChildNames (): any {
      let filteredArray = JSON.parse(JSON.stringify(this.absents))
      if (this.filterObj.serviceId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          return Number(el.serviceId) === Number(this.filterObj.serviceId)
        })
      }
      if (this.filterObj.groupId !== '0') {
        filteredArray = filteredArray.filter((el: any) => {
          console.log(el.groupId)
          return +el.groupId === +this.filterObj.groupId
        })
      }
      // const filteredArray = JSON.parse(JSON.stringify(this.computedAbsents))
      let options = [] as any
      filteredArray.forEach((absense: any) => {
        options.push({ label: absense.childName, value: absense.childName })
      })
      options = f.removeDuplicatedObj(options, 'value')
      return {
        options: [{ label: 'Выберите ученика', value: 0 }, ...options]
      }
    },
    computedGroupsColumns (): any {
      let resultColumns = this.groupsColumns
      if (this.checkPermissions([4])) {
        resultColumns = resultColumns.filter((col: any) => col.name !== 'openModal' && col.name !== 'checkbox')
      }
      return resultColumns
    }
  },
  components: {
    Table,
    Select,
    DateRangePicker,
    LoadingSpinner,
    Modal,
    MoveLessonModal,
    Label,
    Button,
    FileLoad
  }
})

