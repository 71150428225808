import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

import { planningSaveRequestType, planninggroupitemRequestType } from './api-types'

const planningFindUrl = 'planning/find'
const planningSaveUrl = 'planning/save'
const planningchapterFindUrl = 'planningchapter/find'
const planningchapterSaveUrl = 'planningchapter/save'
const planningitemSaveUrl = 'planningitem/save'
const planningitemRemoveUrl = 'planningitem/remove'
const planninggroupitemFindUrl = 'planninggroupitem/find'
const planninggroupitemSaveUrl = 'planninggroupitem/save'

export const planningFindApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planningFindUrl, data)
  return result.data.data
}

export const planningSaveApi = async (data: planningSaveRequestType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planningSaveUrl, data)
  return result.data.data
}

export const planningchapterFindApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planningchapterFindUrl, data)
  return result.data.data
}

export const planningchapterSaveApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planningchapterSaveUrl, data)
  return result.data.data
}

export const planningitemSaveApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planningitemSaveUrl, data)
  return result.data.data
}

export const planningitemRemoveApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planningitemRemoveUrl, data)
  return result.data.data
}

export const planninggroupitemFindApi = async (data: planninggroupitemRequestType) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planninggroupitemFindUrl, data)
  return result.data.data
}

export const planninggroupitemSaveApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(planninggroupitemSaveUrl, data)
  return result.data.data
}
