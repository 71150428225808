import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

const getServicesUrl = 'services/get'
const addServiceUrl = 'service/save'
const updateServiceUrl = ''
const removeServiceUrl = 'service/remove'
const getDirectionsUrl = 'directions/get'
const saveAbonementItemUrl = 'abonement/save'
const removeAbonementItemUrl = 'abonement/remove'
const getContractsTemplatesUrl = 'templates'

// groupInclude: true
// todo remove from groups.api
export const getAllServicesWithGroupsApi = async (data?) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getServicesUrl, data)
  return result.data.data
}

export const getDirectionsApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getDirectionsUrl)
  return result.data.data
}

export const getServicesByServiceTypeIdApi = async (data) => { // serviceType: 1 | 2
  if (data.serviceTypeId) {
    data.serviceType = data.serviceTypeId
  }
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getServicesUrl, data)
  return result.data.data
}

export const getServicesAbonementsApi = async () => { // todo
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getServicesUrl, { serviceType: 1 })
  return result.data.data
}

export const removeAbonementItemApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(removeAbonementItemUrl, data)
  return result.data.data
}

export const saveAbonementItemApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveAbonementItemUrl, data)
  return result.data.data
}

export const getServicesType2Api = async () => { // todo
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getServicesUrl, { serviceType: 2 })
  return result.data.data
}

export const addServiceApi = async (item: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(addServiceUrl, item)
  return result.data.data
}

export const getContractsTemplatesApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.get(getContractsTemplatesUrl)
  return result.data.data
}

export const removeServiceApi = async (item: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(removeServiceUrl, item)
  return result.data.data
}
