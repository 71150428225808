import store from '@/store'
import { getGroupsByServiceIdApi, getGroupByIdApi } from '@/store/groups/api-requests'
import { getContractPrivateApi } from '@/store/privateData/api-requests'
import * as f from '@/services/sharedFunctions'
import * as teacherService from '@/store/teachers/service'
import moment from 'moment'

export const addTeachersNamesToContract = async (teachers: any, contract: any) => {
  let contractTeachersNames = ''
  if (contract.contractServices && Array.isArray(contract.contractServices) && contract.contractServices.length) {
    if (contract.contractServices[0].userGroup && contract.contractServices[0].userGroup.user && contract.contractServices[0].userGroup.user.userDetail) {
      const getPrivate = store.getters['user/getPdType']
      if (getPrivate === 1 && store.getters['privateData/getLoaded']) {
        const privateUserData = store.getters['privateData/getUserPrivateData'](contract.contractServices[0].userGroup.user.id)
        if (privateUserData) {
          contract.studentName = privateUserData.firstName + ' ' + privateUserData.lastName
        } else {
          // просто продолжаем с данными с бэка
          const firstName = contract.contractServices[0].userGroup.user.userDetail.firstName || ''
          const lastName = contract.contractServices[0].userGroup.user.userDetail.lastName || ''
          contract.studentName = firstName + ' ' + lastName
        }
        const privateContractData = store.getters['privateData/getContractPrivateData'](contract.id)
        if (privateContractData) {
          contract.parent = privateContractData.parent
          contract.phone = privateContractData.phoneNumber
          contract.passport = privateContractData.passport
        }
      } else {
        const firstName = contract.contractServices[0].userGroup.user.userDetail.firstName || ''
        const lastName = contract.contractServices[0].userGroup.user.userDetail.lastName || ''
        contract.studentName = firstName + ' ' + lastName
        // contract.parent = f.convertWordInNameString(contract.parent, 1)
      }
      contract.studentName = f.convertWordInNameString(contract.studentName, 2)
      contract.parent = f.convertWordInNameString(contract.parent, 1)
    }
    contract.contractServices = contract.contractServices.map((contractService: any) => {
      let groupTeachersNames = '' as any
      if (contractService.userGroup.group && contractService.userGroup.group.teacherId) {
        const foundGroupTeacher = teachers.find((teacher: any) => {
          return Number(contractService.userGroup.group.teacherId) === Number(teacher.id)
        })
        if (foundGroupTeacher) {
          const groupTeacherName = f.getLastNameAndInitialsFromObject(foundGroupTeacher)
          contractTeachersNames += (contractTeachersNames !== '') ? '<br>' + groupTeacherName : groupTeacherName
          contractService.userGroup.group.teacherName = groupTeacherName
          groupTeachersNames = groupTeacherName
        }
      }
      if (contractService.userGroup.group &&
      contractService.userGroup.group.serviceModules &&
      Array.isArray(contractService.userGroup.group.serviceModules) &&
      contractService.userGroup.group.serviceModules.length) {
        contractService.userGroup.group.serviceModules = contractService.userGroup.group.serviceModules.map((groupModule: any) => {
          if (groupModule.teacherId) {
            const foundGroupModuleTeacher = teachers.find((teacher: any) => {
              return Number(groupModule.teacherId) === Number(teacher.id)
            })
            if (foundGroupModuleTeacher) {
              const groupModuleTeacherName = f.getLastNameAndInitialsFromObject(foundGroupModuleTeacher)
              contractTeachersNames += '<br>' + groupModuleTeacherName
              groupModule.teacherName = groupModuleTeacherName
              groupTeachersNames += '<br>' + groupModuleTeacherName
            }
          }
          return groupModule
        })
      }
      contractService.groupTeachersNames = groupTeachersNames
      return contractService
    })
  }
  contract.contractTeachersNames = contractTeachersNames
  return contract
}

export const convertGetContractsResult = async (contracts: any) => {
  return teacherService.loadTeachersIfNone().then((teachers) => {
    teachers = JSON.parse(JSON.stringify(teachers))
    // return contracts.map((contract: any) => {
    //   return addTeachersNamesToContract(teachers, contract)
    // })
    return Promise.all(contracts.map(async (contract: any) => {
      return addTeachersNamesToContract(teachers, contract)
    }))
  })
}

export const loadContractsByDateRangeIfNone = async (queryObj: any) => {
  const lastQuery = store.getters['contracts/getQuery']
  const contracts = await store.getters['contracts/getContracts']
  if (contracts && contracts.length > 0 && JSON.stringify(queryObj) === JSON.stringify(lastQuery)) {
    return contracts
  } else {
    return await store.dispatch('contracts/loadContracts', queryObj).then(() => {
      return store.getters['contracts/getContracts']
    })
  }
}

export const loadContractIfNone = async (queryObj: any) => {
  const foundContract = store.getters['contracts/getContract'](queryObj.id)
  if (foundContract) {
    // console.log(1)
    return foundContract
  } else {
    // console.log(2)
    return store.dispatch('contracts/loadContract', queryObj).then(() => {
      return store.getters['contracts/getContract'](queryObj.id)
    })
  }
}

export const convertGetContractResult = async (contract: any) => {
  return teacherService.loadTeachersIfNone().then((teachers) => {
    teachers = JSON.parse(JSON.stringify(teachers))
    return addTeachersNamesToContract(teachers, contract)
  })
}
