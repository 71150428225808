





























import Vue from 'vue'
import DatePicker from 'uiv/dist/DatePicker'
import Input from '@/components/form/Input.vue'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export default Vue.extend({
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    params: {
      required: false
    }
  },
  data () {
    return {
      id: uuidv4(),
      opened: false,
      date: new Date().toDateString() as any,
      computedValue: null as any,
      localObj: {
        uiv: {
          datePicker: {
            clear: 'Очистить',
            today: 'Сегодня',
            month: 'Месяц',
            month1: 'Январь',
            month2: 'Февраль',
            month3: 'Март',
            month4: 'Апрель',
            month5: 'Май',
            month6: 'Июнь',
            month7: 'Июль',
            month8: 'Август',
            month9: 'Сентябрь',
            month10: 'Октябрь',
            month11: 'Ноябрь',
            month12: 'Декабрь',
            year: 'Год',
            week1: 'Пн',
            week2: 'Вт',
            week3: 'Ср',
            week4: 'Чт',
            week5: 'Пт',
            week6: 'Сб',
            week7: 'Вс'
          },
          timePicker: {
            am: 'дня',
            pm: 'вечера'
          },
          modal: {
            cancel: 'Отмена',
            ok: 'OK'
          }
        }
      },
      thisParams: null as any
    }
  },
  mounted () {
    if (this.params) {
      this.thisParams = this.params
      if (this.thisParams.format) {
        if (moment(this.value, this.thisParams.format, true).isValid()) {
          const date = moment(this.value, this.thisParams.format).toDate()
          this.date = moment(date).format('yyyy-MM-DD')
          this.computedValue = this.date
        } else {
          console.log('date is not valid')
          this.computedValue = null
        }
      }
    } else {
      this.date = this.value
      this.computedValue = this.value
    }
  },
  methods: {
    hide () {
      if (this.opened) {
        this.opened = false
      }
    },
    show () {
      this.opened = true
    },
    update (event: any) {
      this.$emit('input', this.convertOutput(event))
    },
    convertOutput (value) {
      if (this.params && this.thisParams) {
        if (this.thisParams.format) {
          value = moment(value).format(this.thisParams.format)
        }
      }
      return value
    }
  },
  watch: {
    date (newValue, oldValue) {
      // console.log(newValue)
      this.computedValue = newValue
      this.$emit('input', this.convertOutput(newValue))
      // return newValue
    },
    value (newValue, oldValue) {
      this.date = newValue
    }
  },
  components: {
    DatePicker,
    Input
  }
})

