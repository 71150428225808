/* eslint-disable @typescript-eslint/no-unused-vars */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_SERVICES_CUSTOMER, ADD_SERVICE_GROUP, UPDATE_SERVICE_GROUP, REMOVE_SERVICE_GROUP, REMOVE_SERVICE_ABONEMENT, UPDATE_SERVICE_ABONEMENT, SET_INITIAL_STATE, SET_PROMISE, SET_LOADING, SET_SERVICES, SET_ALL_SERVICES_WITH_GROUPS_LOADED, ADD_SERVICE, UPDATE_SERVICE, REMOVE_SERVICE, SET_SERVICE_DIRECTIONS } from './mutations'
import { RootState } from '@/store/state'
import { ServicesState, Service } from './types'
import { removeServiceApi, getAllServicesWithGroupsApi, getServicesByServiceTypeIdApi, addServiceApi, getDirectionsApi, saveAbonementItemApi, removeAbonementItemApi } from './api-requests'
import { parseAxiosError } from '@/services/api'
import * as servicesService from '@/store/services/service'
import { createGroupsApi, updateGroupsApi, getGroupByIdApi } from '@/store/groups/api-requests'
import * as f from '@/services/sharedFunctions'

type InformationContext = ActionContext<ServicesState, RootState>

const getDefaultState = () => {
  return {
    services: [],
    loading: false,
    serviceDirections: [],
    allServicesWithGroupsLoaded: false,
    promise: null,
    servicesCustomer: {}
  }
}

const initialState: ServicesState = getDefaultState()

const getters: GetterTree<ServicesState, RootState> = {
  services (state: ServicesState, rootState: RootState): Service[] {
    return state.services
  },
  getServices (state: ServicesState, rootState: RootState): Service[] {
    return state.services
  },
  getAllServicesWithGroupsLoaded (state: ServicesState, rootState: RootState): boolean {
    return state.allServicesWithGroupsLoaded
  },
  getServicesCustomer (state: ServicesState, rootState: RootState): Service[] {
    return state.servicesCustomer
  },
  getServicesByServiceType (state: ServicesState, rootState: RootState): any {
    return (serviceType) => {
      // console.log(state.services)
      const tempServices = JSON.parse(JSON.stringify(state.services))
      return tempServices.filter((el: any) => el.serviceType === serviceType)
      // return state.services.filter((service: any) => Number(service.serviceTypeId) === 2)
    }
  },
  getLoading (state: ServicesState, rootState: RootState): boolean {
    return state.loading
  },
  getPromise (state: ServicesState, rootState: RootState): boolean {
    return state.promise
  },
  getService (state: ServicesState, rootState: RootState): any {
    return (serviceId) => {
      const foundService = state.services.find((el: any) => el.id === serviceId)
      if (foundService) {
        return foundService.name
      } else {
        return false
      }
    }
  },
  getServiceById (state: ServicesState, rootState: RootState): any {
    return (serviceId) => {
      const foundService = state.services.find((el: any) => el.id === serviceId)
      if (foundService) {
        return foundService
      } else {
        return false
      }
    }
  },
  getServiceGroups (state: ServicesState, rootState: RootState): any {
    return (serviceId) => {
      const foundService = state.services.find((el: any) => el.id === serviceId)
      if (foundService && foundService.groups && Array.isArray(foundService.groups)) {
        return foundService.groups
      } else {
        return false
      }
    }
  },
  getServiceIdByGroupId (state: ServicesState, rootState: RootState): any {
    return (groupId) => {
      const tempServices = JSON.parse(JSON.stringify(state.services))
      let result = null as any
      tempServices.forEach((service: any) => {
        if (service.groups && Array.isArray(service.groups) && service.groups.length) {
          service.groups.forEach((group: any) => {
            if (Number(group.id) === Number(groupId)) {
              result = service.id
            }
          })
        }
      })
      return result
    }
  },
  getServiceTypeByGroupId (state: ServicesState, rootState: RootState): any {
    return (groupId) => {
      const tempServices = JSON.parse(JSON.stringify(state.services))
      let result = null as any
      tempServices.forEach((service: any) => {
        if (service.groups && Array.isArray(service.groups) && service.groups.length) {
          service.groups.forEach((group: any) => {
            if (Number(group.id) === Number(groupId)) {
              result = service.serviceType
            }
          })
        }
      })
      return result
    }
  },
  getServicePropByGroupId (state: ServicesState, rootState: RootState): any {
    return (groupId, prop) => {
      const tempServices = JSON.parse(JSON.stringify(state.services))
      let result = null as any
      tempServices.forEach((service: any) => {
        if (service.groups && Array.isArray(service.groups) && service.groups.length) {
          service.groups.forEach((group: any) => {
            if (Number(group.id) === Number(groupId)) {
              result = service[prop]
            }
          })
        }
      })
      return result
    }
  },
  getGroupById (state: ServicesState, rootState: RootState): any {
    return (groupId) => {
      const tempServices = JSON.parse(JSON.stringify(state.services))
      let result = {} as any
      tempServices.forEach((service: any) => {
        if (service.groups && Array.isArray(service.groups) && service.groups.length) {
          service.groups.forEach((group: any) => {
            if (Number(group.id) === Number(groupId)) {
              result = group
            }
          })
        }
      })
      return result
    }
  },
  getAllServicesGroups (state: ServicesState, rootState: RootState): any {
    const tempServices = JSON.parse(JSON.stringify(state.services))
    const groups = [] as any
    tempServices.forEach((service: any) => {
      if (service.groups && Array.isArray(service.groups) && service.groups.length) {
        service.groups.forEach((group: any) => {
          groups.push(group)
        })
      }
    })
    return groups
  },
  getServiceDirections (state: ServicesState, rootState: RootState): any[] {
    return state.serviceDirections
  }
}

const mutations: MutationTree<ServicesState> = {
  [SET_INITIAL_STATE] (state: ServicesState) {
    Object.assign(state, getDefaultState())
  },
  [SET_SERVICES] (state: ServicesState, payload: Service[]) {
    state.services = payload
  },
  [SET_ALL_SERVICES_WITH_GROUPS_LOADED] (state: ServicesState, payload: any) {
    state.allServicesWithGroupsLoaded = payload
  },
  [SET_SERVICES_CUSTOMER] (state: ServicesState, payload: any) {
    state.servicesCustomer = payload
  },
  [SET_PROMISE] (state: ServicesState, payload: any) {
    state.promise = payload
  },
  [ADD_SERVICE] (state: ServicesState, payload: Service) {
    state.services.push(payload)
  },
  [UPDATE_SERVICE] (state: ServicesState, payload: any) {
    console.log(payload)
    state.services = state.services.map(item => {
      if (item.id === payload.id) {
        item = Object.assign({}, item, payload)
      }
      return item
    })
  },
  [UPDATE_SERVICE_ABONEMENT] (state: ServicesState, payload: any) {
    state.services = state.services.map(item => {
      if (item.id === payload.id) {
        if (item.abonements && Array.isArray(item.abonements) && item.abonements.length) {
          item.abonements = item.abonements.map(abon => {
            if (+abon.id === +payload.abonId) {
              abon = Object.assign({}, abon, payload)
              abon.id = abon.abonId
              abon.totalPrice = abon.countHours ? abon.price * Number(abon.countHours) : null
            }
            return abon
          })
        }
      }
      return item
    })
  },
  [REMOVE_SERVICE_ABONEMENT] (state: ServicesState, payload: any) {
    state.services = state.services.map(item => {
      if (item.id === payload.serviceId) {
        if (item.abonements && Array.isArray(item.abonements) && item.abonements.length) {
          const index = item.abonements.findIndex(abon => Number(abon.id) === Number(payload.abonId))
          if (index > -1) {
            item.abonements.splice(index, 1)
          }
        }
      }
      return item
    })
  },
  [ADD_SERVICE_GROUP] (state: ServicesState, payload: any) {
    state.services = state.services.map(item => {
      if (item.id === payload.serviceId) {
        if (item.groups && Array.isArray(item.groups)) {
          item.groups.push(payload) // todo
        } else {
          item.groups = [] as any
          item.groups.push(payload) // todo
        }
        console.log(item.groups)
      }
      return item
    })
  },
  [UPDATE_SERVICE_GROUP] (state: ServicesState, payload: any) {
    state.services = state.services.map(item => {
      if (item.id === payload.serviceId) {
        if (item.groups && Array.isArray(item.groups) && item.groups.length) {
          item.groups = item.groups.map(group => {
            if (+group.id === +payload.id) {
              group = Object.assign({}, payload)
            }
            return group
          })
        }
      }
      return item
    })
  },
  /**
   * payloaa.id = service id
   * payload.groupId
   */
  [REMOVE_SERVICE_GROUP] (state: ServicesState, payload: any) {
    state.services = state.services.map(item => {
      if (item.id === payload.id) {
        if (item.groups && Array.isArray(item.groups) && item.groups.length) {
          console.log(item)
          const index = item.groups.findIndex(group => Number(group.id) === Number(payload.groupId))
          if (index > -1) {
            item.groups.splice(index, 1)
          }
        }
        console.log(item)
      }
      return item
    })
  },
  [REMOVE_SERVICE] (state: ServicesState, payload: Service) {
    const index = state.services.findIndex(item => item.id === payload.id)
    state.services.splice(index, 1)
  },
  [SET_SERVICE_DIRECTIONS] (state: ServicesState, payload: any) {
    state.serviceDirections = payload
  },
  [SET_LOADING] (state: ServicesState, payload: boolean) {
    state.loading = payload
  }
}

const actions: ActionTree<ServicesState, RootState> = {
  async loadAllServicesWithGroups ({ commit, dispatch, state }: InformationContext, payload: any): Promise<Service[]> {
    try {
      commit(SET_LOADING, true)
      let result = await getAllServicesWithGroupsApi(payload)
      if (result && result.length && result[0].services) {
        const servicesCustomer = { ...result[0] }
        delete servicesCustomer.services
        // servicesCustomer.cbcType = 1
        commit(SET_SERVICES_CUSTOMER, servicesCustomer)
        result = await servicesService.addTeachersNames(result[0].services)
        commit(SET_SERVICES, result)
        commit(SET_ALL_SERVICES_WITH_GROUPS_LOADED, true)
      }
      commit(SET_LOADING, false)
      return result
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setInitialState ({ commit, dispatch, state }: InformationContext) {
    try {
      commit(SET_INITIAL_STATE)
      return true
    } catch (error) {
      return error
    }
  },
  async addService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      const result = await addServiceApi(item)
      if (result) {
        delete result.groupCount
        delete result.groups
        if (result.abonements && Array.isArray(result.abonements) && result.abonements.length) {
          result.abonements = f.removeDuplicatedObj(result.abonements, 'id')
        }
        const prepared = await servicesService.prepareOneService(result)
        if (prepared) {
          commit(ADD_SERVICE, prepared)
          return true
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      const result = await addServiceApi(item)
      if (result) {
        delete result.groupCount // warning
        delete result.groups
        if (result.abonements && Array.isArray(result.abonements) && result.abonements.length) {
          result.abonements = f.removeDuplicatedObj(result.abonements, 'id')
        }
        const prepared = await servicesService.prepareOneService(result)
        if (prepared) {
          commit(UPDATE_SERVICE, prepared)
          return true
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  addServiceToStore ({ commit, dispatch, state }: InformationContext, item: any): any {
    try {
      commit(ADD_SERVICE, item)
    } catch (error) {
      console.log(error)
    }
  },
  async loadServiceDirections ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      const result = await getDirectionsApi()
      commit(SET_SERVICE_DIRECTIONS, result)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setPromise  ({ commit, dispatch, state }: InformationContext, payload: any) {
    commit(SET_PROMISE, payload)
  },
  async updateAbonementInService ({ commit, dispatch, state }: InformationContext, payload: any) {
    try {
      const result = await saveAbonementItemApi(payload.postObj)
      if (result) {
        commit(UPDATE_SERVICE_ABONEMENT, payload.storeObj)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async removeAbonementInService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      const obj = {
        id: item.abonId
      }
      const result = await removeAbonementItemApi(obj)
      if (result) {
        commit(REMOVE_SERVICE_ABONEMENT, item)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateGroupInService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      commit(SET_LOADING, true)
      const result = await updateGroupsApi(item)
      if (result) {
        let loadedGroup = await getGroupByIdApi({ id: item.id })
        loadedGroup = loadedGroup[0]
        const preparedGroup = await servicesService.prepareOneGroupInService(loadedGroup)
        commit(UPDATE_SERVICE_GROUP, preparedGroup)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async removeGroupInService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      // const result = await updateServiceApi(item)
      commit(REMOVE_SERVICE_GROUP, item)
      // return result
    } catch (error) {
      // return Promise.reject(parseAxiosError(error))
    }
  },
  async addGroupInService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      commit(SET_LOADING, true)
      const result = await createGroupsApi(item)
      if (result) {
        result.teacherId = item.teacherId
        if (result.serviceModules && Array.isArray(result.serviceModules) && result.serviceModules.length) {
          result.serviceModules = result.serviceModules.map((sMod: any, i: number) => {
            sMod.teacherId = item.serviceModules[i].teacherId
            return sMod
          })
        }
        const preparedGroup = await servicesService.prepareOneGroupInService(result)
        commit(ADD_SERVICE_GROUP, preparedGroup)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async removeService ({ commit, dispatch, state }: InformationContext, item: any) {
    try {
      const result = await removeServiceApi({ id: item.id })
      if (result) {
        commit(REMOVE_SERVICE, item)
        return true
      }
    } catch (error) {
      return Promise.reject(error)
    } finally {
      //
    }
  }
}

export const services = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
