


















































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { getAllTeachersApi, saveTeacherApi, searchExternalTeacherApi, removeTeacherApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import * as TeachersService from '@/store/teachers/service'
import TeachersSOTRList from '@/pages/teachers/TeachersSOTRList.vue'
import TeachersOuterList from '@/pages/teachers/TeachersOuterList.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Button from '@/components/table/Button.vue'
import moment from 'moment'
import Calendar from '@/pages/groups/GroupsEditCalendar.vue'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

export default Vue.extend({
  props: [],
  data () {
    return {
      activeTab: 1,
      columnsTeachers: [
        { title: '#', name: 'number', class: 'sm-bg-green' },
        { title: 'Фамилия', name: 'lastName', class: 'd-none d-sm-table-cell md-bg-red xl-bg-none' },
        { title: 'Имя', name: 'firstName', class: 'd-none d-md-table-cell' },
        { title: 'Отчество', name: 'middleName', class: 'd-none d-lg-table-cell' },
        { title: 'Отчество 2', name: 'middleName2', class: 'd-none d-xl-table-cell' },
        { title: '', name: 'remove', text: '', btn: true, btnText: 'Убрать', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'removeTeacher' },
        { title: '', name: 'remove', text: '', btn: true, btnText: 'Убрать', btnIcon: '', btnClass: 'link danger', onClick: 'method', params: 'removeTeacher' }
      ],
      listMode: true,
      getuserGroupVisitUrl: 'usergroupvisit/getbydate',
      axiosInstance: null as any
    }
  },
  mounted () {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}`
    })
    this.setErrorInterceptor(this.axiosInstance)
  },
  methods: {
    sendWithToken () {
      this.setAuthorizationHeader(this.axiosInstance, 1)
      this.getuserGroupVisit()
    },
    sendWithoutToken () {
      this.setAuthorizationHeader(this.axiosInstance)
      this.getuserGroupVisit()
    },
    setAuthorizationHeader (a: AxiosInstance, token = null as any) {
      // functional/immutable-data
      // eslint-disable-next-line
      if (token) {
        token = `Bearer ${localStorage.getItem('accessToken') || ''}`
      }
      a.defaults.headers.common.Authorization = token
    },
    setErrorInterceptor (instance) {
      instance.interceptors.response.use(undefined, (error) => {
        console.log(error)
        console.log(error.response.status)
        if (error.response.status === 500) {
          // localStorage.clear()
          this.$store.dispatch('user/logout')
          Swal.fire({ title: 'storage cleared', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      })
    },
    removeTeacher (data: any) {
      console.log(data)
    },
    async getuserGroupVisit () {
      try {
        const result = await this.getuserGroupVisitApi()
        if (result) {
          Swal.fire({ title: 'Все норм', icon: 'success', text: result, showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).response.data.errorMessage, 'error')
      }
    },
    async getuserGroupVisitApi () {
      const data = {
        date: '2022-01-25',
        groupId: 228
      }
      const result: AxiosResponse<{
        data: any;
        }> = await this.axiosInstance.post(this.getuserGroupVisitUrl, data)
      return result.data.data
    }
  },
  computed: {
    ...mapGetters({
      teachersLoading: 'teachers/getTeachersLoading',
      teachers: 'teachers/getTeachers'
    }),
    computedTeachers (): any {
      const newTeachers = JSON.parse(JSON.stringify(this.teachers))
      newTeachers.forEach((el: any, i: number) => {
        el.middleName2 = el.middleName
        el.number = i + 1
        return el
      })
      // console.log(newTeatchers)
      return newTeachers.filter((el: any) => {
        return el.lastName.length > 0
      })
    }
  },
  components: {
    Table,
    LoadingSpinner,
    Button,
    Calendar
  }
})

