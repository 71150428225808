








import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
type BaseData = {
  id: string;
  // valueModel: string | number | null;
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4()
      // valueModel: null
    }
  },
  props: {
    label: {
      required: true,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    params: {
      required: false,
      type: Object
    }
  },
  methods: {
    // update (event: Event) {
    //   const target = event.target as HTMLTextAreaElement
    //   this.$emit('input', target.value)
    //   // console.log(target.value)
    // }
  },
  watch: {
    // value (val1, val2) {
    //   console.log(this.value)
    //   this.valueModel = val1
    // }
  },
  mounted () {
    // console.log(this.value)
    // this.valueModel = this.value
    // this.$emit('input', this.value)
  }
})
