import axios from '@/services/axios'
import { AxiosResponse } from 'axios'

const getServicesWithGroupsUrl = 'services/get'
const getGroupsByUrl = 'groups'
const createGroupsUrl = 'groups/create'
const updateGroupsUrl = 'groups/update'
const removeGroupsUrl = 'groups/update'
// const getAllGroupsUrl = ''
const getStudentsByGroupsUrl = 'groups/userlist'
const getGroupsWithReceiptsUrl = 'receipt/report'
const updateDaysInGroupRowUrl = 'groups/schedulecorrection'
const createReceiptsByGroupsUrl = 'receipts/createByGroups'
const groupReceiptDetailsUrl = 'group/receipt/details'
const closeSchoolYearUrl = 'groups/closeschoolyear'
const getReceiptsUrl = 'receipt/cartulary'
const usergroupRemoveUrl = 'usergroup/remove'
const usergroupSaveReceiptCorrectionUrl = 'usergroup/savereceiptcorrection'
const usergroupGetReceiptCorrectionUrl = 'usergroup/getreceiptcorrection'

// serviceType: 1 | 2,
// groupInclude: true
export const getServicesWithGroupsApi = async (data?) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getServicesWithGroupsUrl, data)
  return result.data.data
}

export const getGroupByIdApi = async (data) => { // id: id
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getGroupsByUrl, data)
  return result.data.data
}

export const getGroupsByServiceIdApi = async (data) => { // serviceId: id
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getGroupsByUrl, data)
  return result.data.data
}

export const createGroupsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createGroupsUrl, data)
  return result.data.data
}

export const updateGroupsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateGroupsUrl, data)
  return result.data.data
}

export const removeGroupsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(removeGroupsUrl, data)
  return result.data.data
}

export const getStudentsByGroupsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getStudentsByGroupsUrl, data)
  return result.data.data
}

// {
//   "month": 12,
//   "year": 2022
// }
export const getGroupsWithReceiptsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getGroupsWithReceiptsUrl, data)
  return result.data.data
}

export const updateDaysInGroupRowApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(updateDaysInGroupRowUrl, data)
  return result.data.data
}

export const createReceiptsByGroupsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createReceiptsByGroupsUrl, data)
  return result.data.data
}

export const groupReceiptDetailsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(groupReceiptDetailsUrl, data)
  return result.data.data
}

export const closeSchoolYearApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(closeSchoolYearUrl)
  return result.data.data
}

export const getReceiptsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getReceiptsUrl, data)
  return result.data.data
}

export const usergroupRemoveApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(usergroupRemoveUrl, data)
  return result.data.data
}

export const usergroupSaveReceiptCorrectionApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(usergroupSaveReceiptCorrectionUrl, data)
  return result.data.data
}

export const usergroupGetReceiptCorrectionApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(usergroupGetReceiptCorrectionUrl, data)
  return result.data.data
}
