







































































































import Vue from 'vue'
import { getStudentsByGroupsApi, getGroupsByServiceIdApi, usergroupRemoveApi } from '@/store/groups/api-requests'
import { getServicesAbonementsApi, getServicesType2Api } from '@/store/services/api-requests'
import Select from '@/components/form/Select.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Button from '@/components/table/Button.vue'
import { contractGetApi } from '@/store/cart/api-request'
import * as TeachersService from '@/store/teachers/service'
import * as ServicesService from '@/store/services/service'

export default Vue.extend({
  props: {
    //
  },
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    return {
      studentsNoResult: null as any,
      services: [] as any,
      groups: [] as any,
      selectMonthParams: {
        options: selectMonthOptions
      },
      selectYearParams: {
        options: [
          { label: 2021, value: 2021 },
          { label: 2022, value: 2022 }
        ]
      },
      getStudentsApiObject: {
        serviceId: null,
        groupId: null,
        month: moment().month() + 1,
        year: moment().year()
      } as any,
      columnsStudents: [
        { title: '№', name: 'number', width: '40px' },
        { title: 'ФИО', name: 'name', align: 'left' },
        { title: '№ договора', name: 'contractNumber', class: 'fzBigger' },
        { title: 'С какого числа', name: 'dateFrom' },
        { title: 'По какое число', name: 'dateTo' },
        { title: '', name: 'goToContract', width: '30px', text: '', btn: true, btnText: '', btnIcon: 'file-text-o', btnClass: 'primary', onClick: 'method', params: 'goToContract', align: 'right' }
      ],
      students: [] as any,
      loading: false,
      noGroupResetOnServiceAssign: false
    }
  },
  mounted () {
    // console.log(this.$store.getters['navigation/getPageNavigation'](this.$route.name))
    const foundNavInStore = this.$store.getters['navigation/getPageNavigation'](this.$route.name)
    if (foundNavInStore) {
      this.noGroupResetOnServiceAssign = true
      this.getStudentsApiObject = JSON.parse(JSON.stringify(foundNavInStore))
    }
    if (this.$route.query.propService) {
      this.getStudentsApiObject.serviceId = this.$route.query.propService
    }
    if (this.$route.query.propGroup) {
      this.getStudentsApiObject.groupId = Number(this.$route.query.propGroup)
      this.noGroupResetOnServiceAssign = true
    }
    if (this.$route.query.propMonth && this.$route.query.propYear) {
      this.getStudentsApiObject.month = Number(this.$route.query.propMonth)
      this.getStudentsApiObject.year = Number(this.$route.query.propYear)
    }

    if (this.$route.query.propNoInitSearch && this.$route.query.propNoInitSearch === 'true') {
      //
    } else {
      const interval = setInterval(() => {
        if (this.services.length) {
          clearInterval(interval)
          if (this.isValidGetApiObject(this.getStudentsApiObject) === true) {
            this.getStudents()
          }
        }
      }, 1000)
    }
    this.getServices()
  },
  methods: {
    async getServices () {
      try {
        const result = await ServicesService.loadAllServicesIfNone()
        if (result) {
          this.services = result
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async goToContract (item: any) {
      if (item.contractServices && Array.isArray(item.contractServices) && item.contractServices.length > 0) {
        if (item.contractServices[0] && item.contractServices[0].contract && item.contractServices[0].contract.id) {
          const contractId = item.contractServices[0].contract.id
          // const fullBackQuery = { propService: this.getStudentsApiObject.serviceId.toString(), propGroup: this.getStudentsApiObject.groupId.toString(), propMonth: this.getStudentsApiObject.month.toString(), propYear: this.getStudentsApiObject.year.toString() }
          // console.log(this.$route.query)
          // console.log(this.$route.name)
          this.$store.dispatch('navigation/addPageNavigation', { routeName: this.$route.name, data: this.getStudentsApiObject })
          // this.$store.dispatch('navigation/addPageNavigation', { routeName: 'Contract', data: { stepBack: this.$route.name } })
          // this.$router.push({ name: 'Contract', params: { contractId: contractId.toString() }, query: fullBackQuery })
          this.$router.push({ name: 'Contract', params: { contractId: contractId.toString() } })
        }
      } else {
        Swal.fire('', 'Что-то пошло не так:(', 'error')
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async getStudents () {
      this.studentsNoResult = false
      this.students = []
      if (this.getStudentsApiObject.month < 1 || !this.getStudentsApiObject.month) {
        Swal.fire('Ошибка', 'Введите месяц', 'error')
      } else if (!this.getStudentsApiObject.groupId || this.getStudentsApiObject.groupId === '0') {
        Swal.fire('Ошибка', 'Выберите группу', 'error')
      } else {
        try {
          this.getStudentsApiObject.groupId = Number(this.getStudentsApiObject.groupId)
          this.loading = true
          const result = await getStudentsByGroupsApi(this.getStudentsApiObject)
          if (result) {
            this.students = result
          } else {
            this.studentsNoResult = true
            // Swal.fire('', 'По вашему запросу данных не обнаружено', 'error')
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
        } finally {
          this.loading = false
          this.noGroupResetOnServiceAssign = false
        }
      }
    },
    isValidGetApiObject (obj) {
      return obj.groupId !== null && obj.groupId !== '0'
    },
    updateService (id: any) {
      this.getStudentsApiObject.serviceId = id.toString()
      this.students = []
      if (!this.$route.query.propGroup) {
        if (this.noGroupResetOnServiceAssign === false) {
          this.getStudentsApiObject.groupId = null
        }
      }
      if (id !== '0') {
        this.getGroupsByServiceIdApi(id)
      }
    },
    async getGroupsByServiceIdApi (id) { // todo remove
      this.loading = true
      const obj = {
        serviceId: id
      }
      try {
        const result = await getGroupsByServiceIdApi(obj)
        if (result) {
          this.groups = result
        } else {
          this.groups = []
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        // console.log('t2')
        this.loading = false
      }
    },
    updateGroup (id: any) {
      // console.log(id)
      this.getStudentsApiObject.groupId = id
    },
    updateMonth (month: number) {
      // console.log(month)
      this.getStudentsApiObject.month = month
    },
    updateYear (year: number) {
      // console.log(year)
      this.getStudentsApiObject.year = year
    }
  },
  computed: {
    computedServices (): any {
      const options = this.services.map((el: any) => {
        el.label = el.name
        el.value = el.id
        return el
      })
      return {
        options: [...options, { label: 'Выберите услугу', value: 0 }]
      }
    },
    ...mapGetters({
      teachers: 'teachers/getTeachers',
      checkPermissions: 'user/checkPermissions'
    }),
    computedGroups (): any {
      const options = this.groups.map((el: any) => {
        let teacher = ''
        if (el.teacherId) {
          const foundTeacher = this.teachers.find((elT: any) => {
            return Number(el.teacherId) === Number(elT.id)
          })
          if (foundTeacher) {
            teacher = ' / ' + f.getLastNameAndInitialsFromObject(foundTeacher)
          }
        }
        el.label = el.name + teacher
        el.value = el.id
        return el
      })
      return {
        options: [...options, { label: 'Выберите группу', value: '0' }]
      }
    },
    computedStudents (): any {
      return this.students.map((el: any, i: number) => {
        if (el.user && el.user.userDetail) {
          el.number = i + 1
          el.name = f.getLastNameAndInitialsFromObject(el.user.userDetail)
        }
        if (el.contractServices && Array.isArray(el.contractServices) && el.contractServices.length > 0) {
          if (el.contractServices[0].contract && el.contractServices[0].contract.number) {
            el.contractNumber = el.contractServices[0].contract.number
          }
        }
        return el
      })
    }
  },
  components: {
    Table,
    Select,
    // InputNumber,
    LoadingSpinner,
    Button
  }
})

