import store from '@/store'
import * as f from '@/services/sharedFunctions'
import * as teacherService from '@/store/teachers/service'
import moment from 'moment'

export const loadAllServicesIfNone = async () => {
  const loaded = store.getters['services/getAllServicesWithGroupsLoaded']
  const promise = store.getters['services/getPromise']
  if (loaded === true) {
    return store.getters['services/getServices']
  }
  if (promise) {
    return promise
  }
  const actionPromise = store.dispatch('services/loadAllServicesWithGroups', { groupInclude: true })
  store.dispatch('services/setPromise', actionPromise)
  return actionPromise
}

export const addServiceToStoreIfNone = async (newService) => {
  const services = store.getters['services/getServices']
  if (services && Array.isArray(services) && services.length > 0) {
    const foundService = services.find((el: any) => el.id === newService.id)
    if (foundService) {
      // console.log('isset service')
    } else {
      store.dispatch('services/addServiceToStore', newService)
    }
  } else {
    store.dispatch('services/addServiceToStore', newService)
  }
}

export const getServiceLoadIfNone = async (id) => {
  const foundService = store.getters['services/getService'](id)
  if (foundService) {
    return foundService
  } else {
    console.log('todo load service')
    // console.log(store.state.services)
  }
}

export const getServiceDirectionsLoadIfNone = async () => {
  const serviceDirections = store.getters['services/getServiceDirections']
  if (serviceDirections && serviceDirections.length > 0) {
    return serviceDirections
  } else {
    return store.dispatch('services/loadServiceDirections').then(() => {
      return store.getters['services/getServiceDirections']
    })
  }
}

export const getServicesCustomer = async () => {
  const loaded = store.getters['services/getAllServicesWithGroupsLoaded']
  if (loaded) {
    return store.getters['services/getServicesCustomer']
  } else {
    return loadAllServicesIfNone().then(() => {
      return store.getters['services/getServicesCustomer']
    })
  }
}

export const addTeachersToServiceGroup = (teachers: any, service: any, group: any) => {
  if (group.teacherId && group.teacherId > 0 && teachers.length > 0) {
    const teacher = teachers.find((t: any) => Number(t.id) === Number(group.teacherId))
    if (teacher) {
      group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
    }
  }
  if (group.schedules) {
    group.schedule = f.getScheduleStringFromObject(group.schedules)
  }
  group.startTime = group.startTime ? group.startTime.replace(' 00:00:00', '') : ''
  group.endTime = group.endTime ? group.endTime.replace(' 00:00:00', '') : ''
  group.serviceId = service.id
  group.serviceName = service.name
  group.serviceTypeId = service.serviceType // todo check and remove
  group.serviceType = service.serviceType
  group.serviceCountHours = service.countHours
  if (group.serviceModules && Array.isArray(group.serviceModules) && group.serviceModules.length) {
    group.serviceModules = group.serviceModules.map((sMod: any) => {
      if (sMod.teacherId && sMod.teacherId > 0 && teachers.length > 0) {
        const sModTeacher = teachers.find((tch: any) => tch.id === sMod.teacherId.toString())
        if (sModTeacher) {
          sMod.teacherName = f.getLastNameAndInitialsFromObject(sModTeacher)
        }
      }
      if (sMod.schedules && Array.isArray(sMod.schedules) && sMod.schedules.length) {
        if (sMod.schedules[0] && sMod.schedules[0].scheduleItems && Array.isArray(sMod.schedules[0].scheduleItems) && sMod.schedules[0].scheduleItems.length) {
          sMod.schedules[0].scheduleItems = f.removeDuplicatedObj(sMod.schedules[0].scheduleItems, 'id')
        }
      }
      return sMod
    })
  }
  return group
}

export const prepareServiceWithGroups = (service: any, teachers: any) => {
  service.price = service.price ? f.currencyConvert(service.price) : null
  service.totalPrice = service.countHours ? service.price * Number(service.countHours) : null
  // todo delete
  // if (service.name === 'Биология') {
  //   service.templateId = 2
  // }
  // todo delete
  if (service.template && service.template.name) {
    service.templateName = service.template.name
  }
  if (Array.isArray(service.abonements) && service.abonements.length > 0) {
    service.abonements.map((abonement: any) => {
      abonement.price = abonement.price ? f.currencyConvert(abonement.price) : null
      abonement.totalPrice = abonement.countHours ? abonement.price * Number(abonement.countHours) : null
      return abonement
    })
  }
  if (Array.isArray(service.groups) && service.groups.length > 0) {
    service.groups.map(group => {
      return addTeachersToServiceGroup(teachers, service, group)
    })
  }
  return service
}

export const addTeachersNames = async (servicesWithGroups) => {
  return teacherService.loadTeachersIfNone().then((teachers) => {
    teachers = JSON.parse(JSON.stringify(teachers))
    servicesWithGroups = JSON.parse(JSON.stringify(servicesWithGroups))
    if (Array.isArray(servicesWithGroups) && servicesWithGroups.length > 0) {
      return servicesWithGroups.map((service: any) => {
        return prepareServiceWithGroups(service, teachers)
      })
    } else {
      return []
    }
  })
}

export const prepareOneService = async (service: any) => {
  return teacherService.loadTeachersIfNone().then((teachers) => {
    return prepareServiceWithGroups(service, teachers)
  })
}

export const prepareOneGroupInService = async (group: any) => {
  return teacherService.loadTeachersIfNone().then((teachers) => {
    const service = store.getters['services/getServiceById'](group.serviceId)
    return addTeachersToServiceGroup(teachers, service, group)
  })
}
