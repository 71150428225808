

















































































































import * as servicesService from '@/store/services/service'
import { Printd } from 'printd'
import Vue from 'vue'
import DateRangePicker from '@/components/form/DateRangePicker.vue'
import Button from '@/components/table/Button.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import { usergroupGetReceiptCorrectionApi, usergroupSaveReceiptCorrectionApi } from '@/store/groups/api-requests'
import { usergroupvisitGetbyperiodApi } from '@/store/attendanceSheet/api-requests'
import Swal from 'sweetalert2'
import * as f from '@/services/sharedFunctions'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { planningFindApi, planninggroupitemFindApi } from '@/store/calendarPlanning/api-requests'

export default Vue.extend({
  props: {
    groupId: {
      required: true,
      type: [String, Number]
    }
  },
  data () {
    return {
      group: {} as any,
      servicePlanning: [] as any,
      planningItemsLoading: true,
      planningItems: [] as any,
      cssText: `
      .printing {
        font-family: sans-serif;
        width: 500px;
        border: solid 1px #ccc;
        text-align: center;
        padding: 1em;
        margin: 2em auto;
      }

      button {
        background-color: #f0f0f0;
        border: solid 1px #bbb;
        padding: 10px;
        font-size: 15px;
        border-radius: 5px;
      }

      pre {
        color: #c7254e;
      }

      h4 {
        width:100%;
        text-align: center;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table th, table td {
          padding: 0.75rem;
          vertical-align: top;
          border: 1px solid #d0d0d0;
          border-collapse: collapse;
          background-color: red;
      }

      table {
        break-after: page;
        background: red;
      }
      ` as any,
      printd: new Printd(),
      serviceType: null as any,
      apiObj: {
        groupId: null,
        fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        toDate: moment().endOf('month').format('YYYY-MM-DD')
      } as any,
      moment: moment,
      groupName: null as any,
      months: 'Январь',
      result: null as any,
      loading: true,
      userGroupVisits: [] as any,
      colsInitial: [
        'num',
        'childName'
      ] as any,
      cols: [] as any,
      rows: [] as any
    }
  },
  mounted () {
    servicesService.loadAllServicesIfNone().then(() => {
      const foundGroup = this.getGroupById(this.groupId)
      if (foundGroup) {
        this.groupName = foundGroup.name
        this.serviceType = foundGroup.serviceType
        this.apiObj.groupId = this.groupId
        if (this.apiObj.groupId) {
          this.usergroupvisitGetbyperiodApi().then(() => {
            this.planningItemsGet(+this.groupId).then(() => {
              this.planningFindApi(+foundGroup.serviceId)
            })
          })
        }
      }
    })
  },
  methods: {
    async planningFindApi (serviceId: number) {
      // this.planningItemsLoading = true
      try {
        const result = await planningFindApi({ serviceId: serviceId })
        if (result) {
          result.forEach((el: any) => {
            if (el.planningChapters && Array.isArray(el.planningChapters) && el.planningChapters.length) {
              el.planningChapters.forEach((ele: any) => {
                if (ele.planningItems && Array.isArray(ele.planningItems) && ele.planningItems.length) {
                  ele.planningItems.forEach((elem: any) => {
                    this.servicePlanning.push(elem)
                  })
                }
              })
            }
          })
          console.log(this.servicePlanning)
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.planningItemsLoading = false
      }
    },
    async planningItemsGet (groupId: number) {
      try {
        this.planningItemsLoading = true
        const result = await planninggroupitemFindApi({ groupId: groupId })
        this.planningItems = result
        // console.log(result)
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        // this.planningItemsLoading = false
      }
    },
    print () {
      // this.printd = new Printd()
      setTimeout(() => {
        const tableRef = document.getElementById('myElement1')
        if (tableRef) {
          this.printd.print(tableRef, [this.cssText])
        }
      }, 500)
    },
    changeApiDate (data: any) {
      console.log(data)
      this.apiObj.fromDate = data.startDate
      this.apiObj.toDate = data.endDate
      this.usergroupvisitGetbyperiodApi()
    },
    async usergroupvisitGetbyperiodApi () {
      // const data = {
      //   groupId: 237,
      //   fromDate: '2022-02-01',
      //   toDate: '2022-02-28'
      // }
      try {
        this.loading = true
        this.userGroupVisits = await usergroupvisitGetbyperiodApi(this.apiObj)
        const dates = this.userGroupVisits.map((day: any) => day.date)
        this.cols = [...this.colsInitial, ...dates]
        const childs = [] as any
        this.userGroupVisits.forEach((day: any) => {
          if (day.userVisits && Array.isArray(day.userVisits) && day.userVisits.length) {
            day.userVisits.forEach((userVisit: any) => {
              if (userVisit.user && userVisit.user.userDetail) {
                const foundChild = childs.find((ch: any) => ch.userId === userVisit.user.id) // новый ряд или тот же
                // понять, что ставить
                let updatedMark = null as any
                if (userVisit.movement) { // перенесен
                  updatedMark = userVisit.movement.mark === 1 ? 'x' : ' '
                }
                if (userVisit.userGroupVisit && userVisit.userGroupVisit.createdAt !== null) {
                  updatedMark = userVisit.userGroupVisit.mark === 1 ? 'x' : 'н'
                } else {
                  updatedMark = ' '
                }
                if (foundChild) {
                  childs.forEach((ch: any) => {
                    if (ch.userId === userVisit.user.id) {
                      Object.assign(ch, { [day.date]: updatedMark })
                    }
                  })
                } else {
                  childs.push({ ...userVisit, ...{ userId: userVisit.user.id, num: childs.length + 1, [day.date]: updatedMark }, ...{ childName: f.getLastNameAndInitialsFromObject(userVisit.user.userDetail) } })
                }
              }
            })
          }
        })
        this.rows = childs
        // for (let i = 0; i < 114; i++) {
        //   this.rows.push(childs)
        // }
        // this.rows = [...childs, ...childs]
        // console.log(this.rows)
        return true
      } catch (error) {
        if ((error as any).data) {
          if ((error as any).data.errorMessage === 'Ошибка Базы данных.') {
            Swal.fire({
              title: 'Ошибка',
              icon: 'error',
              text: (error as any).data.errorMessage,
              confirmButtonText: 'Перезагрузить',
              allowOutsideClick: false,
              backdrop: true,
              allowEscapeKey: false
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload()
              }
            })
          } else {
            Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
          }
        }
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters({
      checkPermissions: 'user/checkPermissions',
      getGroupById: 'services/getGroupById'
    }),
    computedColsDates (): any {
      const arr = [...this.cols]
      arr.splice(0, this.colsInitial.length)
      return arr
    },
    computedMonths (): any {
      let arr = this.computedColsDates.map((month: any) => ({ mock: 1, date: new Date(month).toLocaleString('default', { month: 'long' }) }))
      arr = f.removeDuplicatedObj(arr, 'date')
      return arr.map((ar: any) => ar.date).join(', ')
    },
    computedUserGroupVisits (): any {
      let result = [] as any
      if (this.userGroupVisits && Array.isArray(this.userGroupVisits) && this.userGroupVisits.length) {
        result = this.userGroupVisits.map((visit) => {
          const foundDate = this.planningItems.find((el: any) => el.fact === visit.date)
          if (foundDate) {
            if (foundDate.planningItemId) {
              const foundServicePlanning = this.servicePlanning.find((el: any) => el.id === foundDate.planningItemId)
              if (foundServicePlanning) {
                visit.text = foundServicePlanning.name
                visit.countHours = foundServicePlanning.countHours
              }
            }
          }
          return visit
        })
      }
      return result
    }
  },
  components: {
    Button,
    DateRangePicker,
    LoadingSpinner
  }
})

