


























import Vue from 'vue'
import Input from '@/components/form/Input.vue'
import Textarea from '@/components/form/Textarea.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import RadioButton from '@/components/form/RadioButton.vue'
import Select from '@/components/form/Select.vue'
import FieldsGroup from '@/components/form/FieldsGroup.vue'
import DatePicker from '@/components/form/DatePickr.vue'
import Hidden from '@/components/form/Hidden.vue'
import InputNumber from '@/components/form/InputNumber.vue'
import Label from '@/components/form/Label.vue'
import RadioToggle from '@/components/form/RadioToggle.vue'
import TimePicker from '@/components/form/TimePickr.vue'
import Button from '@/components/form/ButtonAdapter.vue'

export type DataTypes = {
  formValues: any;
}
export default Vue.extend({
  name: 'FormBuilder',
  props: ['config'],
  watch: {
    config (val1, val2) {
      this.config.map((f: any) => {
        // console.log(this.formValues)
        Vue.set(this.formValues, f.name, f.value)
      })
    }
  },
  mounted () {
    // console.log(this.formValues)
  },
  data (): DataTypes {
    return {
      formValues: {}
    }
  },
  methods: {
    getWidthClass (width: string) {
      if (width === '50%') {
        return 'col-6'
      } else if (width === '70%') {
        return 'col-8'
      } else if (width === '30%') {
        return 'col-4'
      } else if (width === '25%') {
        return 'col-3'
      } else if (width === 'equal') {
        return 'col'
      }
    },
    updateField (field: string, value: string) {
      this.formValues[field] = value
      this.$emit('update', { values: this.formValues, field: field })
    }
  },
  computed: {
    //
  },
  components: {
    Input,
    Textarea,
    Checkbox,
    RadioButton,
    Select,
    FieldsGroup,
    DatePicker,
    Hidden,
    InputNumber,
    Label,
    RadioToggle,
    TimePicker,
    Button
  },
  created () {
    //
  }
})

