









import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { addToCartApi, getCartItemsApi, removeFromCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'

export default Vue.extend({
  props: ['userId', 'condensed'],
  data () {
    return {
      cartItemsColumns: [
        { title: 'Услуга', name: 'serviceName' },
        { title: 'Группа', name: 'groupName' },
        { title: 'Абонемент', name: 'abonementName' },
        { title: 'С', name: 'dateFrom', width: '80px' }, // не совпадает с параметрами группы!
        { title: 'По', name: 'dateTo', width: '80px' }, // не совпадает с параметрами группы!
        { title: '', name: 'remove', btn: true, btnText: '', btnIcon: 'times', btnClass: 'danger', onClick: 'method', params: 'removeFromCart', width: '5%' }
      ],
      cartItemsColumnsCondensed: [
        { title: 'Добавленные услуги:', name: 'serviceName' },
        { title: '', name: 'remove', btn: true, btnText: '', btnIcon: 'times', btnClass: 'danger', onClick: 'method', params: 'removeFromCart', width: '5%' }
      ],
      result: null as any,
      items: [] as any
    }
  },
  mounted () {
    if (this.userId) {
      // this.$store.dispatch('cart/getCartItems', { userId: Number(this.userId) })
    }
  },
  methods: {
    emit (data: any) {
      this[data.methodName](data.item)
    },
    removeFromCart (item: any) {
      console.log(item)
      this.$store.dispatch('cart/removeFromCart', item)
    }
  },
  computed: {
    ...mapState({ cart: 'cart' }),
    cartItems (): void {
      return this.cart.cart.map((el: any) => {
        if (el.abonementId && f.isObject(el.abonement)) {
          el.abonementName = el.abonement.name
        } else {
          el.abonementName = '-'
        }
        if (el.groupId && f.isObject(el.group)) {
          el.groupName = el.group.name
        }
        if (el.serviceId && f.isObject(el.service)) {
          el.serviceName = el.service.name
        }
        return el
      })
    }
  },
  components: {
    Table
  }
})

