import store from '@/store'
import * as f from '@/services/sharedFunctions'
import moment from 'moment'

export const loadAllTeachersIfNone = async () => {
  const externalTeachers = store.getters['teachers/getExternalTeachers']
  if (externalTeachers && Array.isArray(externalTeachers) && externalTeachers.length < 1) {
    // console.log('loadAllTeachersIfNone: no items, load...')
    return store.dispatch('teachers/loadAllTeachersItems')
  } else {
    // console.log('loadAllTeachersIfNone: isset items')
  }
}

export const loadTeachersIfNone = async () => {
  const storeTeachers = store.getters['teachers/getTeachers']
  // const isOperator = store.getters['user/checkPermissions']([3])
  // const isTeacher = store.getters['user/checkPermissions']([4])
  // const teachersArr = [] as any
  // console.log(isOperator)
  if (storeTeachers && Array.isArray(storeTeachers) && storeTeachers.length < 1) {
    // if (isOperator) {
    return store.dispatch('teachers/loadTeachersItems').then(() => {
      return store.getters['teachers/getTeachers']
    })
    // } else if (!isOperator && isTeacher) {
    //   const user = store.getters['user/user']
    //   if (user.person && user.person.userRoles && Array.isArray(user.person.userRoles) && user.person.userRoles.length) {
    //     user.person.userRoles.forEach((role: any) => {
    //       if (role.teachers && Array.isArray(role.teachers) && role.teachers.length) {
    //         role.teachers.forEach((teacher: any) => {
    //           if (user.person && user.person.login) {
    //             const teacherObj = {
    //               id: teacher.id,
    //               firstName: user.person.login,
    //               lastName: user.person.login,
    //               middleName: user.person.login
    //             }
    //             teachersArr.push(teacherObj)
    //           }
    //         })
    //       }
    //     })
    //   }
    // }
    // return store.dispatch('teachers/setTeachers', teachersArr).then(() => {
    //   return store.getters['teachers/getTeachers']
    // })
  } else if (storeTeachers && Array.isArray(storeTeachers) && storeTeachers.length > 0) {
    return storeTeachers
  }
}

export const getTeacherName = async (id) => {
  return loadTeachersIfNone().then((teachers) => {
    if (teachers) {
      const foundTeacher = teachers.find((el: any) => Number(el.id) === Number(id))
      if (foundTeacher) {
        // console.log(foundTeacher)
        return f.getLastNameAndInitialsFromObject(foundTeacher)
      }
    }
  })
}

export const getCounterTime = (min: number) => {
  return moment(new Date()).add(min, 'm').format('yyyy-MM-DD HH:mm:ss')
}
