import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import { runAxiosAuthInterceptor } from '@/services/axios-auth-interceptor'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import '@/services/formValidators.ts'
import VueNumericInput from 'vue-numeric-input'
import ToggleButton from 'vue-js-toggle-button'

import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(VueScrollTo)
// Vue.use(VueI18n)
Vue.use(VueNumericInput)
Vue.use(ToggleButton)
Vue.use(VueMask)
Vue.directive('listenScroll', {
  inserted: (el, binding) => {
    const f = (evt: Event) => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.directive('clickoutside', {
  inserted: (el: any, binding: any, vnode) => {
    // assign event to the element
    el.clickOutsideEvent = function (event) {
      // here we check if the click event is outside the element and it's children
      if (!(el === event.target || el.contains(event.target))) {
        // if clicked outside, call the provided method
        if (vnode.context) {
          vnode.context[binding.expression](event)
        }
      }
    }
    // register click and touch events
    document.body.addEventListener('click', el.clickOutsideEvent)
    document.body.addEventListener('touchstart', el.clickOutsideEvent)
  },
  unbind: function (el: any) {
    // unregister click and touch events before the element is unmounted
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener('touchstart', el.clickOutsideEvent)
  }
  // stopProp(event) {
  //   event.stopPropagation()
  // },
})

runAxiosAuthInterceptor()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
