























import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { addToCartApi, getCartItemsApi, removeFromCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import FormBuilder from '@/components/form/FormBuilder.vue'
import Print from '@/pages/cart/Print.vue'

export default Vue.extend({
  props: ['userId', 'condensed'],
  data () {
    return {
      triggerPrint: false as any,
      step1ResultColumns: [
        {
          type: 'Input', label: 'Номер договора', name: 'number', width: '50%', disabled: true
        },
        {
          type: 'Input', label: 'ФИО плательщика', name: 'parent', disabled: true
        },
        {
          type: 'Input', label: 'Паспортные данные плательщика', name: 'passport', disabled: true
        },
        {
          type: 'Input', label: 'С', name: 'dateFrom', width: '50%', disabled: true
        },
        {
          type: 'Input', label: 'по', name: 'dateTo', width: '50%', disabled: true
        },
        {
          type: 'Input', label: 'Телефон', name: 'phone', width: '50%', disabled: true
        }
      ],
      step22ResultColumns: [],
      step22ResultColumnsInitial: [
        {
          type: 'Input', label: 'Оплачено', name: 'sum', width: '50%', disabled: true
        }
      ],
      result: null as any,
      items: [] as any,
      id: null as any
      // isStep22: false
    }
  },
  mounted () {
    // if (this.userId) {
    //   this.$store.dispatch('cart/getCartItems', { userId: Number(this.userId) })
    // }
  },
  methods: {
    print () {
      this.triggerPrint = 'print'
      setTimeout(() => {
        this.triggerPrint = null
      }, 100)
    }
  },
  computed: {
    // ...mapState({ cart: 'cart' }),
    ...mapState(['cart']),
    // steps (): void {
    //   return this.cart.steps
    // },
    steps (): any {
      return this.cart.steps.map((step: any) => {
        if (step.step === '1-1') {
          this.step1ResultColumns = f.addValuesToColumns(this.step1ResultColumns, step.data)
          // console.log(step.data)
        }
        if (step.step === '2-2') {
          this.step22ResultColumns = f.addValuesToColumns(this.step22ResultColumnsInitial, step.data)
          // this.isStep22 = true
        }
        return step
      })
    },
    isStep22 (): any {
      let result
      this.cart.steps.forEach((step: any) => {
        if (step.step === '2-2') {
          result = true
        }
      })
      return result
    },
    isStep11 (): any {
      let result
      this.cart.steps.forEach((step: any) => {
        if (step.step === '1-1') {
          result = true
        }
      })
      return result
    }
  },
  components: {
    FormBuilder,
    Print
  }
})

