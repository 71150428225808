





























import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
type BaseData = {
  id: string;
  valueModel: string | number | null;
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4(),
      valueModel: null
    }
  },
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    labelPosition: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    inputType: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    propId: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    readonly: {
      required: false,
      type: Boolean
    },
    mask: {
      required: false
    },
    inputClass: {
      required: false,
      type: String
    }
  },
  methods: {
    update (event: Event) {
      const target = event.target as HTMLTextAreaElement
      this.$emit('input', target.value)
      // console.log(target.value)
    }
  },
  watch: {
    value (val1, val2) {
      // console.log(this.value)
      this.valueModel = val1
    }
  },
  mounted () {
    // console.log(this.value)
    this.valueModel = this.value
    this.$emit('input', this.value)
  }
})
