



































































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { getAllTeachersApi, saveTeacherApi, searchExternalTeacherApi, removeTeacherApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import * as TeachersService from '@/store/teachers/service'
import TeachersSOTRList from '@/pages/teachers/TeachersSOTRList.vue'
import TeachersOuterList from '@/pages/teachers/TeachersOuterList.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Button from '@/components/table/Button.vue'
import moment from 'moment'

export default Vue.extend({
  props: [],
  mounted () {
    TeachersService.loadTeachersIfNone()
  },
  data () {
    return {
      activeTab: 1,
      listMode: true
    }
  },
  methods: {
    async resetAuth (item: any) {
      console.log(item)
      const result = await this.$store.dispatch('teachers/clearTeacherAuth', item)
      if (result) {
        Swal.fire({ title: 'Код авторизации сброшен', icon: 'warning', text: 'Запросите новый', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 5500 })
      }
    },
    async createAuthCode (item: any) {
      try {
        const result = await this.$store.dispatch('teachers/setTeacherAuth', item)
        if (result) {
          Swal.fire({ title: 'Код авторизации создан', icon: 'success', text: 'Время действия - 5 минут', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 5500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    initTab (num: number) {
      this.activeTab = Number(num)
    },
    emit (data: any) {
      this[data.methodName](data.item)
    },
    async removeTeacher (item: any) {
      try {
        const result = await this.$store.dispatch('teachers/removeTeacher', item)
        if (result) {
          Swal.fire({ title: 'Преподаватель удален из реестра', icon: 'success', text: '', showConfirmButton: false, toast: true, position: 'bottom-end', timer: 2500 })
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    }
  },
  computed: {
    ...mapGetters({
      teachersLoading: 'teachers/getTeachersLoading',
      teachers: 'teachers/getTeachers'
    }),
    computedTeachers (): any {
      const newTeatchers = JSON.parse(JSON.stringify(this.teachers))
      // console.log(newTeatchers)
      return newTeatchers.filter((el: any) => {
        return el.lastName.length > 0
      })
    },
    computedColumnsTeachers (): any {
      const getTdType = (row) => {
        if (row.auth) {
          return 'Counter'
        }
        return 'Button'
      }
      if (this.$store.getters['user/getPdType'] === 1) {
        return [
          { title: 'Фамилия', name: 'lastName' },
          { title: 'Имя', name: 'firstName' },
          { title: 'Отчество', name: 'middleName' },
          { title: '', name: 'auth', text: '', type: (row) => getTdType(row), btnText: 'Выдать код авторизации', counterFunc: 'resetAuth', btnIcon: '', btnClass: 'primary', onClick: 'method', params: 'createAuthCode', wrapClass: 'flexAround' },
          { title: '', name: 'remove', text: '', btn: true, btnText: 'Убрать', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'removeTeacher' }
        ]
      } else {
        return [
          { title: 'Фамилия', name: 'lastName' },
          { title: 'Имя', name: 'firstName' },
          { title: 'Отчество', name: 'middleName' },
          { title: '', name: 'remove', text: '', btn: true, btnText: 'Убрать', btnIcon: '', btnClass: 'danger', onClick: 'method', params: 'removeTeacher' }
        ]
      }
    }
  },
  components: {
    Table,
    TeachersSOTRList,
    TeachersOuterList,
    LoadingSpinner,
    Button
  }
})

