







































import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import VueNumericInput from 'vue-numeric-input'
type BaseData = {
  id: string;
  valueModel: string | number | null;
  controls: any;
}
export default Vue.extend({
  data (): BaseData {
    return {
      id: uuidv4(),
      valueModel: null,
      controls: {
        min: -Infinity,
        max: Infinity,
        step: 1
      }
    }
  },
  props: {
    validation: {
      required: false,
      type: String
    },
    errorMessage: {
      required: false,
      type: String
    },
    hasError: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    propId: {
      required: false,
      type: String
    },
    params: {
      required: false,
      type: Object,
      default: function () {
        return {
          min: 0,
          max: Infinity,
          step: 1
        }
      }
    },
    disabled: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    update (value: number) {
      // const target = event.target as HTMLTextAreaElement
      this.$emit('input', value)
      // console.log(value)
    }
    // assignParams () {
    //   if (this.params) {
    //     this.params.foeEach((el: any) => {
    //       if (el.min) {

    //       }
    //       if (el.max) {

    //       }
    //       if (el.step) {

    //       }
    //       if (el.step) {

    //       }
    //     })
    //   }
    //   this.controls = this.params
    //     if (this.thisParams.format) {
    // }
  },
  watch: {
    value (val1, val2) {
      this.valueModel = val1
    }
  },
  mounted () {
    this.valueModel = this.value
    this.$emit('input', this.value)
  },
  components: {
    VueNumericInput
  }
})
