/* eslint-disable @typescript-eslint/no-unused-vars */
import * as privateDataService from '@/store/privateData/service'
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex'
import { SET_USER, CLEAR_USER, SET_USERPERSON } from '@/store/user/mutations'
import { RootState } from '@/store/state'
import { UserState, UserShort, Person, UserTokens, JwtDecodeData } from '@/store/user/types'
import { UserLoginPost, UserSignupPost, UserForgotPost, UserResetPost, UserLoginApiResponseType } from './api-types'
import { AuthApiRequestType } from '@/store/privateData/types'
import { login as userLogin, signup as userSignup, forgot as userForgot, reset as userReset, getPerson as userPersonGet } from '@/store/user/api-requests'
import { parseAxiosError } from '@/services/api'
import jwtDecode from 'jwt-decode'
import store from '@/store'
import router from '@/router/index'

type UserContext = ActionContext<UserState, RootState>

const getDefaultState = () => {
  return {
    user: {
      id: 0,
      tokens: null,
      username: ''
    },
    person: null,
    loggedIn: false
  }
}

const initialState: UserState = getDefaultState()

const getters: GetterTree<UserState, RootState> = {
  user (state: UserState): UserState {
    return state
  },
  checkPermissions (state: UserState, getters) {
    return (permissions: number[]) => {
      let result = false
      const usr = state
      if (usr.person && usr.person.userRoles && Array.isArray(usr.person.userRoles) && usr.person.userRoles.length && Array.isArray(permissions) && permissions.length) {
        usr.person.userRoles.forEach((role: any) => {
          if (permissions.indexOf(Number(role.roleId)) > -1) {
            result = true
          }
        })
      }
      return result
    }
  },
  getPdType (state: UserState, getters) {
    let result = 0
    const usr = getters.user
    if (usr.person && usr.person.userRoles && Array.isArray(usr.person.userRoles) && usr.person.userRoles.length && usr.person.userRoles[0].customer) {
      result = usr.person.userRoles[0].customer.pdType
    }
    return result
  },
  getLoggedIn (state: UserState, getters) {
    return state.loggedIn
  }
}

const mutations: MutationTree<UserState> = {
  [SET_USER] (state: UserState, user: UserShort) {
    state.user = user
  },
  [CLEAR_USER] (state: UserState) {
    Object.assign(state, getDefaultState())
  },
  [SET_USERPERSON] (state: UserState, person: Person) {
    state.person = person
    state.loggedIn = true
  }
}

const actions: ActionTree<UserState, RootState> = {
  async login ({ commit, dispatch, state }: UserContext, data: UserLoginPost): Promise<any> {
    try {
      const result = await userLogin(data) as UserLoginApiResponseType
      const decoded: JwtDecodeData = jwtDecode(result.access_token)
      const user: UserShort = {
        id: decoded.sub,
        // eslint-disable-next-line
        tokens: { access_token: result.access_token, refresh_token: result.refresh_token },
        username: data.username
      }
      commit(SET_USER, user)
      localStorage.setItem('accessToken', result.access_token)
      localStorage.setItem('refreshToken', result.refresh_token)
      const upm: AuthApiRequestType = {
        userPin: result.pin.toString(),
        tokenUpm: result.tokenUpm,
        addressProgram: result.url
      }
      localStorage.setItem('upmTokenToGetToken', result.tokenUpm)
      localStorage.setItem('upmAddressProgram', result.url)
      return store.dispatch('user/getPerson', upm)
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  async getPerson ({ commit }: UserContext, upm? : any): Promise<Person> {
    try {
      const result = await userPersonGet()
      const person: Person = result
      commit(SET_USERPERSON, person)
      if (person.userRoles[0].customer.pdType === 1 && upm) {
        const upmAuth = await store.dispatch('privateData/authOnInit', upm)
        if (upmAuth) {
          privateDataService.loadAllOnInit()
        }
      } else if (person.userRoles[0].customer.pdType === 0) {
        store.dispatch('privateData/clearTimer')
      }
      return result
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async logout ({ commit, dispatch, state }: UserContext): Promise<void> {
    try {
      commit(CLEAR_USER)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      store.dispatch('services/setInitialState')
      store.dispatch('teachers/clearState')
      store.dispatch('privateData/clearTimer')
      store.dispatch('privateData/setInitialState')
      store.dispatch('contracts/setInitialState')
      localStorage.removeItem('upmAddressProgram')
      localStorage.removeItem('upmToken')
      localStorage.removeItem('upmTokenToGetToken')
      if ((router as any).history.current.name !== 'Login') {
        (router as any).push({ name: 'Login' })
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  },
  async logoutUpm ({ commit, dispatch, state }: UserContext): Promise<void> {
    try {
      store.dispatch('privateData/clearTimer')
      store.dispatch('privateData/setInitialState')
      localStorage.removeItem('upmAddressProgram')
      localStorage.removeItem('upmToken')
      store.dispatch('privateData/setAccessDenied', true)
      store.dispatch('privateData/setCloseAuthModal', false)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(parseAxiosError(error as any))
    }
  }
  // todo
  /*
  async signup (
    { commit, dispatch, state }: UserContext,
    data: UserSignupPost
  ): Promise<number> {
    try {
      const result = await userSignup(data)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async forgot (
    { commit, dispatch, state }: UserContext,
    data: UserForgotPost
  ): Promise<string> {
    try {
      const result = await userForgot(data)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  },
  async reset (
    { commit, dispatch, state }: UserContext,
    data: UserResetPost
  ): Promise<void> {
    try {
      const result = await userReset(data)
      return result
    } catch (error) {
      return Promise.reject(parseAxiosError(error))
    }
  }
  */
}

export const user = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
