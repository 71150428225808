import axios, { setCustomErrorHandling } from '@/services/axios'
import { AxiosResponse } from 'axios'
import * as f from '@/services/sharedFunctions'

const findByPaymentKeyAndDateOfBirthUrl = 'users/findByPaymentKeyAndDateOfBirth'
const getClassroomsUrl = 'classes/get'
const getClassroomStudentsUrl = 'users/getbyplaceid'
const createUserUrl = 'users/create'
const contractsGroupCreateUrl = 'contracts/groupcreate'

export const findByPaymentKeyAndDateOfBirthApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(findByPaymentKeyAndDateOfBirthUrl, data)
  return result.data.data
}

export const getClassroomsApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getClassroomsUrl)
  const orderedData = f.orderBy(result.data.data, 'name', 'desc')
  return orderedData
}

export const getClassroomStudentsApi = async (data: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getClassroomStudentsUrl, data)
  const orderedData = f.orderBy(result.data.data, 'lastName', 'desc')
  return orderedData
}

export const createUserApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(createUserUrl, data)
  return result.data.data
}

export const contractsGroupCreateApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(contractsGroupCreateUrl, data)
  return result.data.data
}
