




































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import LoadingBackdrop from '@/components/main/LoadingBackdrop.vue'

export default Vue.extend({
  props: [],
  data () {
    return {
      localStorage: localStorage,
      internetConnetcionStatus: true
    }
  },
  created () {
    //
  },
  mounted () {
    window.onoffline = (event) => {
      this.internetConnetcionStatus = false
    }
    window.ononline = (event) => {
      this.internetConnetcionStatus = true
    }
  },
  methods: {
    internetConnetcionStatusColor () {
      return this.internetConnetcionStatus ? 'green' : 'red'
    },
    locationReload () {
      location.reload()
    },
    ...mapActions('user', { userLogout: 'logout' })
  },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  components: {
    LoadingBackdrop
  }
})
