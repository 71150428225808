import axios from '@/services/axios'
import { AxiosResponse } from 'axios'
import { userRegistrationCheckcodeApiRequestType } from './types'

const getTeachersUrl = 'teachers/get'
const saveTeacherUrl = 'teachers/save'
const searchExternalTeacherUrl = 'teachers/find'
const removeTeacherUrl = 'teachers/remove'

// const addServiceUrl = ''
// const updateServiceUrl = ''
// const removeServiceUrl = ''
const userRegistrationGetcodeUrl = 'user/registration/getcode'
const userRegistrationCheckcodeUrl = 'user/registration/checkcode'

export const getAllTeachersApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getTeachersUrl, { externalInclude: true })
  return result.data.data
}

// только преподаватели зарегистрированные
export const getRegisteredTeachersApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getTeachersUrl)
  return result.data.data
}

// добавить преподавателя в список с зарегистрированными
export const saveTeacherApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(saveTeacherUrl, data)
  return result.data.data
}

// найти преподавателя по УЛС
export const searchExternalTeacherApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(searchExternalTeacherUrl, data)
  return result.data.data
}

export const removeTeacherApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(removeTeacherUrl, { teacherId: data.id })
  return result.data.data
}

export const userRegistrationGetcodeApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(userRegistrationGetcodeUrl, { userId: data.userId })
  return result.data.data
}

export const userRegistrationCheckcodeApi = async (data: any) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(userRegistrationCheckcodeUrl, { userId: data.userId, code: data.code } as userRegistrationCheckcodeApiRequestType)
  return result.data.data
}
