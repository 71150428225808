
































































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { getServicesWithGroupsApi } from '@/store/groups/api-requests'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Table from '@/components/Table.vue'
import * as f from '@/services/sharedFunctions'
import * as ServicesService from '@/store/services/service'
import * as TeachersService from '@/store/teachers/service'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import InputNumber from '@/components/form/InputNumber.vue'
import Select from '@/components/form/Select.vue'
import Button from '@/components/table/Button.vue'
import Label from '@/components/form/Label.vue'

export default Vue.extend({
  props: {
    routeEnterServiceTypeId: {
      required: false,
      type: [String, Number],
      default: 2
    }
  },
  data () {
    const selectMonthOptions = [] as any
    for (let i = 1; i <= 12; i++) {
      selectMonthOptions.push({
        label: f.numberToMonth(i, 'full'),
        value: i
      })
    }
    return {
      loading: false,
      pageParams: {
        serviceType: null,
        itemEditRouteName: null
      } as any,
      pageParams1: {
        serviceType: 1,
        itemEditRouteName: 'GroupsAbonementEdit'
      } as any,
      pageParams2: {
        serviceType: 2,
        itemEditRouteName: 'GroupsServiceType2Edit'
      } as any,
      abonements: [] as any,
      searchQuery: '',
      data: [] as any,
      selectMonthValueInitial: moment().month() + 1,
      selectMonthParams: {
        options: selectMonthOptions
      },
      inputNumberYearValueInitial: moment().year(),
      selectYearParams: {
        options: [
          { label: 2021, value: 2021 },
          { label: 2022, value: 2022 }
        ]
      },
      getGroupsByDateApiObject: {
        month: null,
        year: null
      } as any
    }
  },
  mounted () {
    this.loadGroupsOfServiceTypeId(this.routeEnterServiceTypeId)
  },
  methods: {
    updateMonth (month: number) {
      this.getGroupsByDateApiObject.month = Number(month)
    },
    updateYear (year: number) {
      this.getGroupsByDateApiObject.year = year
    },
    async filterGroupsByDate () {
      this.buildTable()
    },
    loadGroupsOfServiceTypeId (id?: any) {
      if (Number(id) === 1) {
        this.pageParams = this.pageParams1
      } else {
        this.pageParams = this.pageParams2
      }
      this.buildTable()
    },
    clearTable () {
      this.data.length = 0
      this.data = []
    },
    buildTable () {
      this.clearTable()
      this.getTeachers().then((teachers) => {
        this.getGroups().then((groups: any) => {
          if (groups.length) {
            groups.forEach(el => {
              if (el.groups && el.groups.length > 0) {
                el.groups.forEach(group => {
                  group.groupId = group.id
                  group.id = el.id
                  group.groupName = group.name
                  group.name = el.name
                  group.editBtn = { case: 'group' }
                  group.removeBtn = { case: 'group' }
                  if (group.teacherId) {
                    const teacher = teachers.find((t: any) => t.id === group.teacherId.toString())
                    group.teacherName = f.getLastNameAndInitialsFromObject(teacher)
                  }
                  const sch = this.getScheduleStringFromObject(group.schedules)
                  group.schedule = sch
                  group.startTime = group.startTime ? group.startTime.replace(' 00:00:00', '') : ''
                  group.endTime = group.endTime ? group.endTime.replace(' 00:00:00', '') : ''
                  if (group.personCount !== 0) {
                    this.data.push(group)
                  }
                })
                // console.log(this.data)
              }
            })
          }
        })
      })
    },
    getScheduleStringFromObject (schedulesArray: any) {
      let result = ''
      schedulesArray.forEach((s: any) => {
        if (!s.removed) {
          s.scheduleItems.forEach((si: any, i: number) => {
            if (!si.removed) {
              const item = f.numberToWeekDay(si.day, 'short') +
              ': ' + si.startRange.slice(0, -3) + '-' + si.endRange.slice(0, -3)
              result += item
              if (i < s.scheduleItems.length) {
                result += '<br>'
              }
            }
          })
        }
      })
      return result
    },
    async getGroups () {
      this.clearTable()
      this.loading = true
      let obj = {
        serviceType: this.pageParams.serviceType,
        groupInclude: true
      }
      obj = { ...this.getGroupsByDateApiObject, ...obj }
      try {
        let result = await getServicesWithGroupsApi(obj)
        if (Array.isArray(result) && result[0] && result[0].services && Array.isArray(result[0].services) && result[0].services.length) {
          result = result[0].services.map((service: any) => {
            if (service.groups && Array.isArray(service.groups) && service.groups.length) {
              service.groups.map((el: any) => {
                el.freeSeatsCount = el.personCount
                el.freeSeatsCount = Number(el.personCount) - Number(el.userCount)
                return el
              })
            }
            return service
          })
          return result
        }
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      } finally {
        this.loading = false
      }
    },
    async getTeachers () {
      this.loading = true
      try {
        return TeachersService.loadTeachersIfNone()
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    computedColumns (): any {
      return [
        { title: 'Услуга', name: 'name', rowspan: 'id' },
        { title: 'Группа', name: 'groupName' },
        { title: 'Преподаватель', name: 'teacherName' },
        { title: 'Кол-во мест в группе', name: 'personCount', class: 'fzBigger' },
        { title: 'Кол-во мест свободных', name: 'freeSeatsCount', class: 'fzBigger' }
      ]
    }
  },
  components: {
    Table,
    LoadingSpinner,
    Label,
    Select,
    Button
  }
})

