






































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { UserLoginPost } from '@/store/user/api-types'
import { ServerError } from '@/services/api'
import { findByPaymentKeyAndDateOfBirthApi, getClassroomsApi, getClassroomStudentsApi, createUserApi } from '@/store/servicesSignUp/api-requests'
import { getServicesType2Api, getServicesAbonementsApi } from '@/store/services/api-requests'
import { getGroupsByServiceIdApi } from '@/store/groups/api-requests'
import { addToCartApi } from '@/store/cart/api-request'
import * as f from '@/services/sharedFunctions'
import Table from '@/components/Table.vue'
import { getAllTeachersApi } from '@/store/teachers/api-requests'
import Swal from 'sweetalert2'
import FormBuilder from '@/components/form/FormBuilder.vue'
import CartList from '@/pages/cart/CartList.vue'
import moment from 'moment'
import Modal from '@/components/Modal.vue'
import StepsResult from '@/pages/cart/StepsResult.vue'
import Button from '@/components/table/Button.vue'
import LoadingSpinner from '@/components/main/LoadingSpinner.vue'
import * as ServicesService from '@/store/services/service'

export default Vue.extend({
  props: [],
  data () {
    return {
      addToCartSum: null,
      serviceTypeChosen: '0',
      // servicesLoading: false,
      servicesColumns: [
        { title: 'Название', name: 'name', rowspan: 'id', btn: true, btnClass: 'link', onClick: 'method', params: 'chooseService' },
        { title: 'Цена', name: 'price', class: 'fzBigger' },
        { title: 'Часы', name: 'countHours', class: 'fzBigger' },
        { title: 'Сумма', name: 'totalPrice', class: 'fzBigger' },
        { title: 'Описание', name: 'description', width: '50%' },
        { title: '', name: 'edit', btn: true, btnText: 'Выбрать', btnIcon: '', btnClass: 'regular2', onClick: 'method', params: 'chooseService', width: '5%' }
      ],
      services: [] as any,
      computedServices: [] as any,
      serviceChooseInited: false,
      noServiceGroupsWarning: null as any,
      serviceAbonementsLoading: false,
      serviceAbonementsColumns: [
        { title: 'Название', name: 'name', rowspan: 'id', btn: true, btnClass: 'link', onClick: 'method', params: 'chooseAbonement' },
        { title: 'Цена', name: 'price' },
        { title: 'Часы', name: 'countHours' },
        { title: 'Сумма', name: 'totalPrice' },
        { title: 'Описание', name: 'description', width: '50%' },
        { title: '', name: 'edit', btn: true, btnText: 'Выбрать', btnIcon: '', btnClass: 'regular2', onClick: 'method', params: 'chooseAbonement', width: '5%' }
      ],
      serviceAbonements: [],
      serviceChosen: null as any,
      serviceAbonementChosen: null as any,
      serviceAbonementChooseInited: false
    }
  },
  mounted () {
    if (this.$store.getters['cart/getStepService']('1-1') === undefined) {
      this.$store.dispatch('cart/clearState')
      this.$emit('forceFirstStep')
    }
    this.serviceTypeChosen = '2'
    this.getServices()
  },
  methods: {
    async getServices () {
      try {
        this.services = await ServicesService.loadAllServicesIfNone()
        this.loadServices()
      } catch (error) {
        Swal.fire('Ошибка', (error as any).data.errorMessage, 'error')
      }
    },
    async loadServices () {
      let result
      if (this.serviceTypeChosen !== '0') {
        try {
          if (+this.serviceTypeChosen === 1) { // todo
            this.computedServices = this.servicesGetter(1)
          } else if (+this.serviceTypeChosen === 2) { // todo
            this.computedServices = this.servicesGetter(2)
          }
          this.computedServices = this.computedServices.map((el: any) => {
            el.totalPrice = el.price * +el.countHours
            return el
          })
          const issetCartItems = await this.$store.getters['cart/getCartItems']
          if (Array.isArray(issetCartItems) && issetCartItems.length) {
            const templateId = issetCartItems[0].service.templateId
            this.computedServices = this.computedServices.filter((el: any) => {
              return Number(el.templateId) === Number(templateId)
            })
          }
        } catch (error) {
          Swal.fire('Ошибка', (error as any), 'error')
        }
      }
    },
    chooseService (item) {
      this.serviceChosen = item
      this.addToCartSum = item.totalPrice
      this.serviceChooseInited = true
      if (+this.serviceTypeChosen === 1) {
        if (item.abonements && Array.isArray(item.abonements) && item.abonements.length) {
          this.serviceAbonements = item.abonements.map((el: any) => {
            el.totalPrice = el.price * Number(el.countHours)
            return el
          })
        } else {
          this.$store.dispatch('cart/addStepService', { step: '2-1', order: 2, data: item })
          this.$emit('nextStep')
        }
        this.$store.dispatch('cart/addStepService', { step: '2-1', order: 2, data: item })
      } else {
        this.$store.dispatch('cart/addStepService', { step: '2-1', order: 2, data: item })
        this.$emit('nextStep')
      }
    },
    cancelChooseService () {
      //
    },
    chooseAbonement (item) {
      this.serviceAbonementChooseInited = true
      this.serviceAbonementChosen = item
      this.addToCartSum = item.totalPrice
      this.$store.dispatch('cart/addStepService', { step: '2-2', order: 2, data: item })
      this.$emit('nextStep')
    },
    goToStep (val: any) {
      this.$emit('nextStep')
    },
    emit (data: any) {
      this[data.methodName](data.item)
    }
  },
  computed: {
    ...mapState(['cart']),
    steps (): void {
      return this.cart.stepsService
    },
    ...mapGetters({
      servicesLoading: 'services/getLoading',
      servicesGetter: 'services/getServicesByServiceType'
    })
  },
  components: {
    Button,
    Table,
    LoadingSpinner
  }
})

