import axiosClass, { AxiosResponse } from 'axios'
import { ApiUsersType, ApiContractType, ApiContractsType, getUsersTypeRequest, AuthApiRequestType, AuthApiResponseType, CreateOrUpdateAccountApiRequestType, ProgramApiResponseType } from '@/store/privateData/types'
import store from '@/store'

const checkDbConnectionUrl = 'CheckDbConnection'

const createOrUpdateAccountUrl = 'CreateOrUpdateAccount'
const getSettingsUrl = 'GetSettings'
const setSettingsUrl = 'SetSettings' // deprecated
const authUrl = 'Auth'
const createUserUrl = 'CreateUser'
const updateUserUrl = 'UpdateUser'
const getUsersUrl = 'GetUsers'
const getUserUrl = 'GetUser'
// CONTRACTS
const createContractUrl = 'CreateContract'
const getContractsUrl = 'GetContracts'
const getContractUrl = 'GetContract'
const updateContractUrl = 'UpdateContract'

export const setHeadersAuthority = () => {
  const foundToken = localStorage.getItem('upmToken')
  // const foundToken = store.getters['user/getUpmToken']
  if (foundToken) {
    return { headers: { authority: foundToken } }
  }
}

const getAddressProgram = () => {
  const data = localStorage.getItem('upmAddressProgram')
  return 'https://' + data + '/Api/V1/'
}

const translateError = (error: Error) => {
  if (error.message === 'Network Error') {
    return 'Нет соединения с программой обработки персональных данных, проверьте настройки'
  } else {
    return error.message
  }
}
const defaultOptions = {
  // baseURL: 'https://localhost:5001/Api/V1/'
}
const axios = axiosClass.create(defaultOptions)
const CancelToken = axiosClass.CancelToken
axios.interceptors.request.use(
  (config) => {
    store.dispatch('privateData/setCloseAuthModal', false)
    // console.log('go')
    if (store.getters['privateData/getUpmAuthCheck'] === false) {
      return {
        ...config,
        cancelToken: new CancelToken((cancel) => cancel('Canceled request'))
      }
    } else {
      return config
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  async (response) => {
    if (response.data && response.data.statusCode === 1008) { // при обновлении нет такой записи в юзерах
      await axios.post(getAddressProgram() + '' + createUserUrl, JSON.parse(response.config.data), setHeadersAuthority())
    } else if (response.data && response.data.statusCode === 1011) { // при обновлении нет такой записи в контрактах
      await axios.post(getAddressProgram() + '' + createContractUrl, JSON.parse(response.config.data), setHeadersAuthority())
    } else if (response.data && response.data.statusCode === 1001) { // доступ запрещен, модалка с auth
      store.dispatch('privateData/setAccessDenied', false)
      store.dispatch('privateData/setAccessDenied', true)
      store.dispatch('privateData/setCloseAuthModal', false)
      return Promise.reject(new Error('Нет доступа к программе обработки персональных данных, проверьте настройки'))
    } else if (response.data && (response.data.statusCode === 1002 || response.data.statusCode === 1003)) {
      return Promise.reject(new Error('Неверные данные для входа'))
    } else if (response.data && response.data.statusCode > 1) {
      return Promise.reject(new Error('Неизвестная ошибка'))
    }
    return response
  }, (error) => {
    // console.log('error сработал в интерсепторе')
    return Promise.reject(new Error(translateError(error)))
    // console.log(error)
    // }
  }
)

export const createOrUpdateAccountApi = async (data: CreateOrUpdateAccountApiRequestType) => {
  const addressProgram = data.addressProgram
  delete data.addressProgram
  const result: AxiosResponse<{
    data: ProgramApiResponseType;
  }> = await axios.post('https://' + addressProgram + '/Api/V1/' + createOrUpdateAccountUrl, data)
  return result.data
}
export const getSettingsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post('https://' + data.addressProgram + '/Api/V1/' + getSettingsUrl, data)
  return result.data
}

export const setSettingsApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post('https://' + data.addressProgram + '/Api/V1/' + setSettingsUrl, data)
  return result.data
}

export const authApi = async (data: any) => {
  // if (!data.tokenUpm) {
  //   const tok = localStorage.getItem('upmTokenToGetToken')
  //   if (tok) {
  //     data.tokenUpm = tok
  //   }
  // }
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post('https://' + data.addressProgram + '/Api/V1/' + authUrl, data)
  return result.data
}

export const createUserApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getAddressProgram() + '' + createUserUrl, data, setHeadersAuthority())
  return result.data
}

export const updateUserApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getAddressProgram() + '' + updateUserUrl, data, setHeadersAuthority())
  return result.data
}

export const getUsersApi = async (data: getUsersTypeRequest) => {
  const result: AxiosResponse<ApiUsersType> = await axios.post(getAddressProgram() + '' + getUsersUrl, data, setHeadersAuthority())
  return result.data
}

export const getUserApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getAddressProgram() + '' + getUserUrl, data, setHeadersAuthority())
  return result.data
}

// CONTRACTS

export const createContractPrivateApi = async (data) => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getAddressProgram() + '' + createContractUrl, data, setHeadersAuthority())
  return result.data
}

export const getContractPrivateApi = async (data) => {
  const result: AxiosResponse<ApiContractType> = await axios.post(getAddressProgram() + '' + getContractUrl, data, setHeadersAuthority())
  return result.data.result
}

export const updateContractPrivateApi = async (data) => {
  const result: AxiosResponse<ApiContractType> = await axios.post(getAddressProgram() + '' + updateContractUrl, data, setHeadersAuthority())
  return result.data
}

export const getContractsPrivateApi = async (data) => {
  const result: AxiosResponse<ApiContractsType> = await axios.post(getAddressProgram() + '' + getContractsUrl, data, setHeadersAuthority())
  return result.data
}

export const checkDbConnectionApi = async () => {
  const result: AxiosResponse<{
    data: any;
  }> = await axios.post(getAddressProgram() + '' + checkDbConnectionUrl, null, setHeadersAuthority())
  return result.data
}
